import Header from './elements/Header'
import Layout from './elements/Layout'
import { H1, H2, H3, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'
import { Youtube1, Youtube2 } from './elements/Youtube'

import Img0 from '../img/company/img0.jpeg'
import Img1 from '../img/company/img1.jpeg'
import Img2 from '../img/company/img2.jpeg'
import Img3 from '../img/company/img3.png'

export default function Company() {
    return (
        <>
            <Header />
            <Layout>
                <H4>会社概要</H4>
                <Image1 img={Img0} />
                <Image1 img={Img1} />
                <Image2 imgs={[
                    { img: Img2 },
                    { img: Img3 }]} />
                <H4>編集部 制作実績</H4>
                <div className="flex justify-center">
                    <div className=" w-full max-w-2xl">
                        <Youtube1 urls={[{ id: "dCDU4wQ-c04" }]} height={'400px'} />
                    </div>
                </div>
                <H4>会社情報</H4>
                <div className="flex justify-center pb-4">
                    <div className="w-full max-w-2xl border divide-y">
                        <div className="flex flex-col sm:flex-row p-3">
                            <div className="sm:w-1/3">
                                法人名
                            </div>
                            <div className="sm:w-2/3">
                                株式会社 Terra-Cco （テラッコ）
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row p-3">
                            <div className="sm:w-1/3">
                                代表
                            </div>
                            <div className="sm:w-2/3">
                                谷村　紀嗣
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row p-3">
                            <div className="sm:w-1/3">
                                設立
                            </div>
                            <div className="sm:w-2/3">
                                2019年3月
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row p-3">
                            <div className="sm:w-1/3">
                                所在地
                            </div>
                            <div className="sm:w-2/3">
                                〒140-0011 東京都品川区東大井5丁目2-7<br />
                                磯部ビル2-202<br />
                                ☎：03-6303-7333　FAX：03-6712-0330<br />
                                E-mail：info@terra-cco.jp
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row p-3">
                            <div className="sm:w-1/3">
                                URL
                            </div>
                            <div className="sm:w-2/3">
                                https://terra-cco.jp
                            </div>
                        </div>
                    </div>
                </div>
                <H4>所在地</H4>
                <div className="flex justify-center">
                    <div className=" w-full max-w-2xl">
                        {/* <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6487.609629237711!2d139.736163!3d35.607881!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd521f984b80bc1f0!2z5p2x5Lqs6YO956uL5ZOB5bed54m55Yil5pSv5o-05a2m5qCh!5e0!3m2!1sja!2sus!4v1669101090371!5m2!1sja!2sus"
                            width="100%"
                            height="300"
                            scrolling="no"
                            allow="encrypted-media"
                        ></iframe> */}
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.804803570697!2d139.73358311156576!3d35.607881272496684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188a7dec5652dd%3A0xca2545f202e14813!2z44OG44Op44OD44Kz!5e0!3m2!1sja!2sjp!4v1691377867259!5m2!1sja!2sjp"
                            width="100%"
                            height="300"
                            scrolling="no"
                            allow="encrypted-media"
                        ></iframe>
                    </div>
                </div>
            </Layout>
        </>
    )
}