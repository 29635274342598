import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H3, H4 } from './elements/Heading'
export default function Blog () {
    return (
        <>
        <Header />
            <Layout>
                <H4>ブログ</H4>
                <p>工事中</p>
            </Layout>
        </>
    ) 
}