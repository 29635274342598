import { useNavigate } from "react-router-dom";
import Logo from '../../img/logo.jpeg'
export default function Footer() {
  const navigate = useNavigate()
  return (
    <div className="container mx-auto">
      <hr className="my-4 border-gray-200 sm:mx-auto dark:border-gray-700" />
      <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://flowbite.com/" className="hover:underline">Terra-Cco（テラッコ）</a></span>
      </div>
    </div>
  )
}
