import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'
import { Youtube1, Youtube2 } from './elements/Youtube'
import { Link as Scroll } from 'react-scroll';
import Saitama1 from '../img/koko-pv/shutoken/saitama/img1.png'
import Saitama2 from '../img/koko-pv/shutoken/saitama/img2.png'
import Saitama3 from '../img/koko-pv/shutoken/saitama/img3.png'
import Saitama4 from '../img/koko-pv/shutoken/saitama/img4.png'
import Saitama5 from '../img/koko-pv/shutoken/saitama/img5.png'

import Chiba1 from '../img/koko-pv/shutoken/chiba/img1.png'
import Chiba2 from '../img/koko-pv/shutoken/chiba/img2.png'
import Chiba3 from '../img/koko-pv/shutoken/chiba/img3.png'
import Chiba4 from '../img/koko-pv/shutoken/chiba/img4.png'
import Chiba5 from '../img/koko-pv/shutoken/chiba/img5.png'
import Chiba6 from '../img/koko-pv/shutoken/chiba/img6.png'
import Chiba7 from '../img/koko-pv/shutoken/chiba/img7.png'

export default function KokoPvShutoken() {
    return (
        <>
            <Header />
            <Layout>
                <H4>私立高校 学校紹介動画（首都圏）</H4>
                <Scroll className="cursor text-xl font-bold underline underline-offset-auto" to="saitama">埼玉県</Scroll>　/
                <Scroll className="cursor text-xl font-bold underline underline-offset-auto" to="chiba">千葉県</Scroll>　/
                <Scroll className="cursor text-xl font-bold underline underline-offset-auto" to="tokyo">東京都</Scroll>
                <section id="saitama">
                    <H2>
                        埼玉県・獨協埼玉中学高等学校
                    </H2>
                    <Image1 img={Saitama1} />
                    <Youtube2 urls={[
                        { id: "5sDb38Z-LMk" },
                        { id: "NCdDo3yVC_k" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "BHsWYFqbN54" },
                        { id: "Us4si2USF9A" },]}
                        height={'200px'} />
                    <H2>
                        埼玉県・秀明英光高等学校
                    </H2>
                    <Image1 img={Saitama2} />
                    <Youtube2 urls={[
                        { id: "e0QvByCpJfc" },
                        { id: null },]}
                        height={'200px'} />
                    <H2>
                        埼玉県・東邦第二高等学校
                    </H2>
                    <Image1 img={Saitama3} />
                    <Youtube2 urls={[
                        { id: "NpTStBl3Zwk" },
                        { id: null },]}
                        height={'200px'} />
                    <H2>
                        埼玉県・開智高等学校
                    </H2>
                    <Image1 img={Saitama4} />
                    <Youtube2 urls={[
                        { id: "egiIAbTjRwM" },
                        { id: "QZ4y7cW03eI" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "Ch3V_N-5VPo" },
                        { id: null },]}
                        height={'200px'} />
                    <H2>
                        埼玉県・開智未来中学・高等学校
                    </H2>
                    <Image1 img={Saitama5} />
                    <Youtube2 urls={[
                        { id: "QhX0Bx0hcOo" },
                        { id: "SHA8tvhl-Gc" },]}
                        height={'200px'} />
                </section>
                <section id="chiba">
                    <H2>
                        千葉県・日出学園中学校高等学校
                    </H2>
                    <Image1 img={Chiba1} />
                    <Youtube2 urls={[
                        { id: "dlq-1tRYAj4" },
                        { id: "dHJapblThB8" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "l-0AE2N-SzU" },
                        { id: null },]}
                        height={'200px'} />
                    <H2>
                        千葉県・日出学園中学校高等学校
                    </H2>
                    <Image1 img={Chiba2} />
                    <Youtube2 urls={[
                        { id: "W7ckWgFMhOY" },
                        { id: "cJPotg7tx9k" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "sGB6SD9W9cs" },
                        { id: "oZhi-tD7tz8" },]}
                        height={'200px'} />
                    <H2>
                        千葉県・桜林高等学校
                    </H2>
                    <Image1 img={Chiba3} />
                    <Youtube2 urls={[
                        { id: "XLiuGunuNHk" },
                        { id: "ChhY7HxRmXc" },]}
                        height={'200px'} />
                    <H2>
                        千葉県・中央学院高等学校
                    </H2>
                    <Image1 img={Chiba3} />
                    <Youtube2 urls={[
                        { id: "6TDkNfAUxAU" },
                        { id: "7upDEW4x1ss" },]}
                        height={'200px'} />
                    <H2>
                        千葉県・昭和学院中学校・高等学校
                    </H2>
                    <Image1 img={Chiba3} />
                    <Youtube2 urls={[
                        { id: "46yXQf1YNUk" },
                        { id: null },]}
                        height={'200px'} />
                    <H2>
                    千葉県・千葉日本大学第一中学校・高等学校
                    </H2>
                    <Image1 img={Chiba3} />
                    <Youtube2 urls={[
                        { id: "hrx7AqXYFuk" },
                        { id: "Y0gPW1Cr6Ek" },]}
                        height={'200px'} />
                </section>
                <section id="tokyo">

                </section>
            </Layout>
        </>
    )
}