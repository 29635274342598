import { H2 } from './Heading'

export const Image1 = ({ img }: { img: any }) => {
  return (
    <div className="pb-4">
      <img src={img} className="w-full mt-2 mb-2" alt="" />
    </div>
  )
}

export const Image2 = ({ imgs }: { imgs: any }) => {
  return (
    <div className="pb-4">
      <div className="grid gap-2 lg:grid-cols-2">
        {imgs.map((img: any, key: any) => (
          <div key={key}>
            {img.title == null ? (
              null
            ) : (
              <H2>{img.title}</H2>
            )}
            <div>
              <div className="cursor">
                <img src={img.img} className="w-full mt-2 mb-2" alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const Image2WithContent = ({ arr }: { arr: any }) => {
  return (
    <div className="pb-4">
      <div className="flex flex-col sm:flex-row">
        {arr.map((data: any, key: any) => (
          <div className="sm:w-1/2 p-2">
            <div key={key}>
              <img src={data.img} className="w-full mt-2 mb-2" alt="" />
              <div className="white-space text-center text-xl text-lime-500 font-bold">
                {data.title}
              </div>
              <div className="white-space text-center pb-4">
                {data.subtitle}
              </div>
              <div className="white-space">
                {data.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const Image2_2 = ({ imgs }: { imgs: any }) => {
  return (
    <div className="grid gap-2 lg:grid-cols-2">
      {imgs.map((img: any, key: any) => (
        <div key={key}>
          {img.title == null ? (
            null
          ) : (
            <H2>{img.title}</H2>
          )}
          <div>
            <div className="cursor">
              {key == 0 ? (
                <img src={img.img} className="w-full mt-2 mb-2" alt="" />
              ) : (
                <>
                  <img src={img.img.above} className="w-full mt-2 mb-2" alt="" />
                  <img src={img.img.below} className="w-full mt-2 mb-2" alt="" />
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export const Image2_3 = ({ imgs }: { imgs: any }) => {
  return (
    <div className="grid gap-2 lg:grid-cols-2">
      {imgs.map((img: any, key: any) => (
        <div key={key}>
          {img.title == null ? (
            null
          ) : (
            <H2>{img.title}</H2>
          )}
          <div>
            <div className="cursor">
              {key == 0 ? (
                <img src={img.img} className="w-full mt-2 mb-2" alt="" />
              ) : (
                <>
                  <img src={img.img.above} className="w-full mt-2 mb-2" alt="" />
                  <img src={img.img.center} className="w-full mt-2 mb-2" alt="" />
                  <img src={img.img.below} className="w-full mt-2 mb-2" alt="" />
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export const Image3 = ({ imgs }: { imgs: any }) => {
  return (
    <div className="grid gap-2 lg:grid-cols-3">
      {imgs.map((img: any, key: any) => (
        <div key={key}>
          {img.title == null ? (
            null
          ) : (
            <H2>{img.title}</H2>
          )}
          <div>
            <div className="cursor">
              <img src={img.above} className="w-full mt-2 mb-2" alt="" />
            </div>
            {img.bottom == null ? (
              null
            ) : (
              <div className="cursor">
                <img src={img.bottom} className="w-full mt-2 mb-2" alt="" />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export const Image4 = ({ imgs }: { imgs: any }) => {
  return (
    <div className="pb-4">
      <div className="grid gap-2 lg:grid-cols-4">
        {imgs.map((img: any, key: any) => (
          <div key={key}>
            {img.title == null ? (
              null
            ) : (
              <H2>{img.title}</H2>
            )}
            <div>
              <div className="cursor">
                <img src={img.img} className="w-full mt-2 mb-2" alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}