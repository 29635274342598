import Header from './elements/Header'
import Layout from './elements/Layout'
import { H4 } from './elements/Heading'
import { Image1, Image2, Image4, Image2_3 } from './elements/Image'

import Img1 from '../img/bosai-freebox/img1.png'
import Img2 from '../img/bosai-freebox/img2.png'
import Img3 from '../img/bosai-freebox/img3.png'
import Img4 from '../img/bosai-freebox/img4.png'
import Img5 from '../img/bosai-freebox/img5.png'
import Img6 from '../img/bosai-freebox/img6.png'
import Img7 from '../img/bosai-freebox/img7.png'
import Img8 from '../img/bosai-freebox/img8.png'
import Img9 from '../img/bosai-freebox/img9.png'
import Img10 from '../img/bosai-freebox/img10.png'

import Img11 from '../img/bosai-freebox/img11.png'
import Img12 from '../img/bosai-freebox/img12.png'
import Img13 from '../img/bosai-freebox/img13.png'
import Img14 from '../img/bosai-freebox/img14.png'
import Img15 from '../img/bosai-freebox/img15.png'
import Img16 from '../img/bosai-freebox/img16.png'

import Img17 from '../img/bosai-freebox/img17.png'
import Img18 from '../img/bosai-freebox/img18.png'
import Img19 from '../img/bosai-freebox/img19.png'
import Img20 from '../img/bosai-freebox/img20.png'
import Img21 from '../img/bosai-freebox/img21.png'

import Banner1 from '../img/bosai-freebox/banner1.jpeg'
import Banner2 from '../img/bosai-freebox/banner2.jpeg'

export default function BosaiFreebox() {
    return (
        <>
            <Header />
            <Layout>
                <H4>防災フリーBOX</H4>
                <Image1 img={Img1} />
                <Image2 imgs={[
                    { img: Img2 },
                    { img: Img3 }
                    ]} />
                <Image2 imgs={[
                    { img: Img4 },
                    { img: Img5 }
                    ]} />
                <div className="grid gap-2 lg:grid-cols-2">
                    {[Banner1, Banner2].map((img, key) => (
                        <div key={key}>
                            <div>
                                <div className="cursor">
                                    <Image1 img={img} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Image1 img={Img6} />
                <Image1 img={Img7} />
                <Image2 imgs={[
                    { img: Img8 },
                    { img: Img9 }
                ]}
                />
                <Image2 imgs={[
                    { img: Img10 },
                    { img: null }
                ]} />
                <div className="pb-4">
                    <a href="/pdf/fax_blank.pdf" download >
                        <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >「個人申し込み用FAX」ダウンロード</button>
                    </a>
                </div>
                <div className="grid gap-2 lg:grid-cols-2">
                    {[Banner1, Banner2].map((img, key) => (
                        <div key={key}>
                            <div>
                                <div className="cursor">
                                    <Image1 img={img} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Image4 imgs={[
                    { img: Img11 },
                    { img: Img12 },
                    { img: Img13 },
                    { img: Img14 },
                ]} />
                <Image2 imgs={[
                    { img: Img15 },
                    { img: Img16 },
                ]} />
                <div className="grid gap-2 lg:grid-cols-2">
                    {[Banner1, Banner2].map((img, key) => (
                        <div key={key}>
                            <div>
                                <div className="cursor">
                                    <Image1 img={img} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Image2_3 imgs={[
                    { img: Img17 },
                    {
                        img: {
                            above: Img18,
                            center: Img19,
                            below: Img20,
                        }
                    }]} />
                <div className="pb-4">
                    <a href="/pdf/leaflet_car.pdf" download >
                        <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >「車載セットチラシ」ダウンロード</button>
                    </a>
                </div>
                <div className="grid gap-2 lg:grid-cols-2">
                    {[Banner1, Banner2].map((img, key) => (
                        <div key={key}>
                            <div>
                                <div className="cursor">
                                    <Image1 img={img} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Image1 img={Img21} />
                <div className="grid gap-2 lg:grid-cols-2">
                    {[Banner1, Banner2].map((img, key) => (
                        <div key={key}>
                            <div>
                                <div className="cursor">
                                    <Image1 img={img} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="pb-4">
                    <a href="/pdf/prevention_goods.pdf" download >
                        <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >「ポーチ入り防災グッズセット・くまもん」ダウンロード</button>
                    </a>
                </div>
            </Layout>
        </>
    )
}