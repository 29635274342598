import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'
import { Youtube1, Youtube2 } from './elements/Youtube'
import { Link as Scroll } from 'react-scroll';
import Saitama1 from '../img/koko-pv/shutoken/saitama/img1.png'
import Saitama2 from '../img/koko-pv/shutoken/saitama/img2.png'
import Saitama3 from '../img/koko-pv/shutoken/saitama/img3.png'
import Saitama4 from '../img/koko-pv/shutoken/saitama/img4.png'
import Saitama5 from '../img/koko-pv/shutoken/saitama/img5.png'

import Chiba1 from '../img/koko-pv/shutoken/chiba/img1.png'
import Chiba2 from '../img/koko-pv/shutoken/chiba/img2.png'
import Chiba3 from '../img/koko-pv/shutoken/chiba/img3.png'
import Chiba4 from '../img/koko-pv/shutoken/chiba/img4.png'
import Chiba5 from '../img/koko-pv/shutoken/chiba/img5.png'
import Chiba6 from '../img/koko-pv/shutoken/chiba/img6.png'
import Chiba7 from '../img/koko-pv/shutoken/chiba/img7.png'

export default function KokoPvShutoken() {
    return (
        <>
            <Header />
            <Layout>
                <H4>私立高校 学校紹介動画（東北地方）</H4>
                <Scroll className="cursor text-xl font-bold underline underline-offset-auto" to="fukushima">福島県</Scroll>
                <section id="fukushima">
                    <H2>
                        福島東稜高校プロモーションビデオ
                    </H2>
                    <Youtube1 urls={[
                        { id: "hqrRBw1HnvE" }
                    ]} height={'500px'} />
                    <H2>
                        福島東稜高校イメージソング『夢への助走』
                    </H2>
                    <Youtube1 urls={[
                        { id: "kfnuCChSlC4" }
                    ]} height={'500px'} />

                </section>
            </Layout>
        </>
    )
}