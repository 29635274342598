import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'
import { Youtube1, Youtube2 } from './elements/Youtube'
import { Link as Scroll } from 'react-scroll';
import Ibaraki1 from '../img/koko-pv/kitakanto/ibaraki/img1.png'
import Ibaraki2 from '../img/koko-pv/kitakanto/ibaraki/img2.png'
import Ibaraki3 from '../img/koko-pv/kitakanto/ibaraki/img3.png'
import Ibaraki4 from '../img/koko-pv/kitakanto/ibaraki/img4.png'
import Ibaraki5 from '../img/koko-pv/kitakanto/ibaraki/img5.png'
import Ibaraki6 from '../img/koko-pv/kitakanto/ibaraki/img6.png'
import Tochigi1 from '../img/koko-pv/kitakanto/tochigi/img1.png'
import Tochigi2 from '../img/koko-pv/kitakanto/tochigi/img2.png'
import Gunma1 from '../img/koko-pv/kitakanto/gunma/img1.png'
import Gunma2 from '../img/koko-pv/kitakanto/gunma/img2.png'
export default function KokoPvKitakanto() {
    return (
        <>
            <Header />
            <Layout>
                <H4>私立高校 学校紹介動画（北関東）</H4>
                <Scroll className="cursor text-xl font-bold underline underline-offset-auto" to="ibaraki">茨城県</Scroll>　/　
                <Scroll className="cursor text-xl font-bold underline underline-offset-auto" to="tochigi">栃木県</Scroll>　/　
                <Scroll className="cursor text-xl font-bold underline underline-offset-auto" to="gunma">群馬県</Scroll>
                <section id="ibaraki">
                    <H2>
                        茨城県・水戸啓明高等学校
                    </H2>
                    <Image1 img={Ibaraki1} />
                    <Youtube2 urls={[
                        { id: "LD1CkxACqk0" },
                        { id: "Ek_ZxWKEz_s" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "-GIAXKB-xVM" },
                        { id: "whv503vMk8c" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "ePWc6xl6KKs" },
                        { id: "cLwup0RnvKk" },]}
                        height={'200px'} />
                    <Image2 imgs={[
                        { img: Ibaraki2 },
                        { img: null }
                    ]} />
                    <H2>
                        茨城県・取手聖徳女子高等学校
                    </H2>
                    <Image1 img={Ibaraki3} />
                    <Youtube2 urls={[
                        { id: "EdM8fso_YaA" },
                        { id: "Gbq3O3fELfk" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "eLI_IwJuILE" },
                        { id: "fGS7Roe75GE" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "It1A2AAN84g" },
                        { id: "TNr7kQChv2Q" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "1Jry3gwMzpw" },
                        { id: "Myh_GcpNXdU" },]}
                        height={'200px'} />
                    <H2>
                        茨城県・岩瀬日本大学高等学校
                    </H2>
                    <Image1 img={Ibaraki4} />
                    <Youtube2 urls={[
                        { id: "kAmFjtx7QZg" },
                        { id: null },]}
                        height={'200px'} />
                    <H2>
                        茨城県・東洋大学附属牛久中学校・高等学校
                    </H2>
                    <Image1 img={Ibaraki5} />
                    <Youtube2 urls={[
                        { id: "O8hfszIX-4w" },
                        { id: "VlBYyCqDskQ" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "0__ZXr0rhlw" },
                        { id: null },]}
                        height={'200px'} />
                    <H2>
                        茨城県・茨城キリスト教学園中学校・高等学校
                    </H2>
                    <Image1 img={Ibaraki6} />
                    <Youtube2 urls={[
                        { id: "YyFT8Y4fBF0" },
                        { id: "HIKhiiZ6IMg" },]}
                        height={'200px'} />
                </section>
                <section id="tochigi">
                    <H2>
                        栃木県・國學院大學栃木高等学校
                    </H2>
                    <Image1 img={Tochigi1} />
                    <Youtube2 urls={[
                        { id: "lDy_hSbOOHo" },
                        { id: null },]}
                        height={'200px'} />
                    <H2>
                        栃木県・宇都宮海星女子学院高等学校
                    </H2>
                    <Image1 img={Tochigi2} />
                    <Youtube2 urls={[
                        { id: "hHAxMZNQv6Q" },
                        { id: "38nsLHUfnQw" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "ygBLCjRvrZw" },
                        { id: "q3zvWIZ8KmI" },]}
                        height={'200px'} />
                    <Youtube2 urls={[
                        { id: "hPIQbzTeLn4" },
                        { id: null },]}
                        height={'200px'} />
                </section>
                <section id="gunma">
                    <H2>
                        群馬県・東京農業大学第二高等学校
                    </H2>
                    <Image1 img={Gunma1} />
                    <Youtube2 urls={[
                        { id: "FBdtW6R4VKc" },
                        { id: null },]}
                        height={'200px'} />
                    <H2>
                        群馬県・高崎健康福祉大学高崎高等学校
                    </H2>
                    <Image1 img={Gunma2} />
                    <Youtube2 urls={[
                        { id: "k0M1F2o4CYk" },
                        { id: null },]}
                        height={'200px'} />
                </section>
            </Layout>
        </>
    )
}