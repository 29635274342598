import reactStringReplace from "react-string-replace"
import { useNavigate } from "react-router-dom";
import Header from './elements/Header'
import Layout from './elements/Layout'

import { Slider1 } from './elements/Slider'
import { Youtube1 } from './elements/Youtube'
import { Image1, Image2, Image2_2, Image3 } from './elements/Image'
import { Card3, Card4, Card5 } from './elements/Card'
import { H2, H3, H4 } from './elements/Heading'

import Img1 from '../img/rekikoto/img1.png'
import Img2 from '../img/rekikoto/img2.png'
import Img3 from '../img/rekikoto/img3.png'
import Img4 from '../img/rekikoto/img4.png'
import Img5 from '../img/rekikoto/img5.png'
import Img6 from '../img/rekikoto/img6.png'
import Img7 from '../img/rekikoto/img7.png'
import Img8 from '../img/rekikoto/img8.png'
import Img9 from '../img/rekikoto/img9.png'
import Img10 from '../img/rekikoto/img10.png'
import Img11 from '../img/rekikoto/img11.jpeg'
import Img12 from '../img/rekikoto/img12.png'
import Img13 from '../img/rekikoto/img13.png'
import Img14 from '../img/rekikoto/img14.png'
import Img15 from '../img/rekikoto/img15.png'
import Img16 from '../img/rekikoto/img16.png'
import Img17 from '../img/rekikoto/img17.png'
import Img18 from '../img/rekikoto/img18.jpeg'
import Img19 from '../img/rekikoto/img19.jpeg'

import Img20 from '../img/rekikoto/img20.png'
import Img21 from '../img/rekikoto/img21.jpeg'
import Img22 from '../img/rekikoto/img22.jpeg'
import Img23 from '../img/rekikoto/img23.jpeg'
import Img24 from '../img/rekikoto/img24.jpeg'
import Img25 from '../img/rekikoto/img25.png'
import Img26 from '../img/rekikoto/img26.jpeg'
import Img27 from '../img/rekikoto/img27.jpeg'
import Img28 from '../img/rekikoto/img28.jpeg'
import Img29 from '../img/rekikoto/img29.jpeg'
import Img30 from '../img/rekikoto/img30.png'
import Img31 from '../img/rekikoto/img31.jpeg'
import Img32 from '../img/rekikoto/img32.jpeg'
import Img33 from '../img/rekikoto/img33.jpeg'
import Img34 from '../img/rekikoto/img34.jpeg'
import Img35 from '../img/rekikoto/img35.jpeg'
import Img36 from '../img/rekikoto/img36.jpeg'
import Img37 from '../img/rekikoto/img37.jpeg'
import Img38 from '../img/rekikoto/img38.jpeg'
import Img39 from '../img/rekikoto/img39.jpeg'

import Elem1 from '../img/rekikoto/card1.png'
import Elem2 from '../img/rekikoto/card2.png'
import Elem3 from '../img/rekikoto/card3.png'
import Elem4 from '../img/rekikoto/card4.png'
import Elem5 from '../img/rekikoto/card5.jpeg'
import Elem6 from '../img/rekikoto/card6.png'
import Elem7 from '../img/rekikoto/card7.png'
import Elem8 from '../img/rekikoto/card8.png'
import Elem9 from '../img/rekikoto/card9.png'
import Elem10 from '../img/rekikoto/card10.png'
import Elem11 from '../img/rekikoto/card11.png'
import Elem12 from '../img/rekikoto/card12.jpeg'

import Swiper1 from '../img/rekikoto/swiper1.png'
import Swiper2 from '../img/rekikoto/swiper2.png'
import Swiper3 from '../img/rekikoto/swiper3.png'
import Swiper4 from '../img/rekikoto/swiper4.png'
import Swiper5 from '../img/rekikoto/swiper5.png'
import Swiper6 from '../img/rekikoto/swiper6.png'
import Swiper7 from '../img/rekikoto/swiper7.png'
import Swiper8 from '../img/rekikoto/swiper8.png'
import Swiper9 from '../img/rekikoto/swiper9.png'
import Swiper10 from '../img/rekikoto/swiper10.png'
import Swiper11 from '../img/rekikoto/swiper11.jpeg'
import Swiper12 from '../img/rekikoto/swiper12.jpeg'
import Swiper13 from '../img/rekikoto/swiper13.jpeg'
import Swiper14 from '../img/rekikoto/swiper14.jpeg'

export default function Rekikoto() {
    const navigate = useNavigate()
    const regExp = /(https?:\/\/\S+)/g

    return (
        <>
            <Header />
            <Layout>
                <H4>レキコト（ハイブリット教材シリーズ）</H4>
                <Image1 img={Img1} />
                <Slider1 slides={[
                    // Swiper1,
                    Swiper2,
                    Swiper3,
                    Swiper4,
                    Swiper5,
                    Swiper6,
                    Swiper7,
                    Swiper8,
                    Swiper9,
                    Swiper10,
                ]} />
                <Youtube1 urls={[
                    { id: "Xy1KbSRPEik" }
                ]} height={'500px'} />
                <Image1 img={Img2} />
                <Image2 imgs={[
                    { img: Img3 },
                    { img: Img4 }]} />
                <Image1 img={Img5} />
                <Image1 img={Img6} />
                <Image2 imgs={[
                    { img: Img7 },
                    { img: null }]} />
                <Image2 imgs={[
                    { img: Img8 },
                    { img: Img9 }]} />
                <Image1 img={Img10} />
                <Image2_2 imgs={[
                    { img: Img11 },
                    {
                        img: {
                            above: Img12,
                            below: Img13,
                        }
                    }]} />
                <Image1 img={Img14} />
                <Image2 imgs={[
                    { img: Img15 },
                    { img: Img16 }]} />
                <div className="pb-4">
                    <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                        <ul>
                            <li className='p-1'>
                                現代の子どもたちは生まれながらにして、生活の中にスマートフォンやタブレット端末があることが当たり前です。
                                しかし、便利さの反面、本来幼少期に伸ばせる「ものごとを認知する技能」を思うように伸ばすことは難しい環境でもあります。
                            </li>
                            <li className='p-1'>
                                本書は特に重要と思われる「器用さ・筆記スキル」「空間認知能力」「処理速度」の３つの認知能力に焦点をあて、編成しました。
                                また今回、課題をアルファベット型にしたのは、自然とアルファベットに触れ、その記憶が英語学習の手助けとなるねらいがあります。
                            </li>
                        </ul>
                    </div>
                </div>
                <Image1 img={Img17} />
                <div className="grid gap-2 lg:grid-cols-2">
                    {[
                        {
                            img: Img18,
                            url: `/contact`
                        },
                        {
                            img: Img19,
                            url: `/contact-kojin`
                        }
                    ].map((item, key) => (
                        <div key={key} className="cursor" onClick={() => navigate(item.url)}>
                            <Image1 img={item.img} />
                        </div>
                    ))}
                </div>
                <H2>レキシとコトバの先生 活用事例（善教舎様・いわき市）</H2>
                <Slider1 slides={[
                    Swiper11,
                    Swiper12,
                    Swiper13,
                    Swiper14
                ]} />
                <div className="pb-4">
                    <a href="/pdf/leaflet_rekikoto.pdf" download >
                        <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >「レキコト活用事例」ダウンロード</button>
                    </a>
                </div>
                <H4>ハイブリット教材シリーズ（税別）</H4>
                <Card5 datas={[
                    { img: Elem1, description: '上巻・縄文～安土桃山' + "\n" + '￥1,430' },
                    { img: Elem2, description: '下巻・江戸～昭和時代' + "\n" + '￥1,430' },
                    { img: Elem3, description: 'セカイシとコトバの先生' + "\n" + '￥1,430' },
                    { img: Elem4, description: 'レキコトEnglish Part1' + "\n" + '￥1,430' },
                    { img: Elem5, description: 'レキコトEnglish Part2' + "\n" + '￥1,430' },
                ]} />
                <Card3 datas={[
                    { img: Elem6, description: 'ビジョンモンスター' + "\n" + '￥1,210' },
                    { img: Elem7, description: '聞き取り用ＣＤ（上・下）' + "\n" + '各￥1,650' },
                    { img: Elem8, description: 'レキコトEnglish Part.1・2　ＣＤ' + "\n" + '各￥1,650' },
                ]} />
                <Card4 datas={[
                    { img: Elem9, description: 'との先生クリアファイル' + "\n" + '20枚組（色組合せ自由）' + "\n" + '￥2,970' },
                    { img: Elem10, description: 'との先生クリアファイル(紫)' + "\n" + '￥165' },
                    { img: Elem11, description: 'との先生クリアファイル(紫)' + "\n" + '￥165' },
                    { img: Elem12, description: 'レキコト✕英語' + "\n" + '読み分けシート' + "\n" + '￥330' },
                ]} />
                <H2>レキシとコトバの先生（上巻）サンプル</H2>
                <Image3 imgs={[
                    { above: Img20, bottom: null, title: null },
                    { above: Img21, bottom: null, title: null },
                    { above: Img22, bottom: null, title: null }]} />
                <Image2 imgs={[
                    { img: Img23 },
                    { img: Img24 }]} />
                <div className="pb-4">
                    <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                        <ul>
                            <li className='p-1'>
                                ・縄文時代から安土桃山時代まで登場人物50名。目次をご覧ください。
                            </li>
                            <li className='p-1'>
                                ・同じ文章で「たて書き」「よこ書き」掲載しております。
                            </li>
                        </ul>
                    </div>
                </div>
                <H2>レキシとコトバの先生（下巻）サンプル</H2>
                <Image3 imgs={[
                    { above: Img25, bottom: null, title: null },
                    { above: Img26, bottom: null, title: null },
                    { above: Img27, bottom: null, title: null }]} />
                <Image2 imgs={[
                    { img: Img28 },
                    { img: Img29 }]} />
                <div className="pb-4">
                    <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                        <ul>
                            <li className='p-1'>
                                ・江戸時代から昭和まで登場人物50名。目次をご覧ください。
                            </li>
                            <li className='p-1'>
                                ・同じ文章で「たて書き」「よこ書き」掲載しております。
                            </li>
                        </ul>
                    </div>
                </div>
                <H2>セカイシとコトバの先生　サンプル</H2>
                <Image3 imgs={[
                    { above: Img30, bottom: null, title: null },
                    { above: Img31, bottom: null, title: null },
                    { above: Img32, bottom: null, title: null }]} />
                <Image2 imgs={[
                    { img: Img33 },
                    { img: Img34 }]} />
                <div className="pb-4">
                    <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                        <ul>
                            <li className='p-1'>
                                ・登場人物50名。目次をご覧ください。
                            </li>
                            <li className='p-1'>
                                ・同じ文章で「たて書き」「よこ書き」掲載しております。
                            </li>
                            <li className='p-1'>
                                ・セカコトならではの工夫された年表が面白い！
                            </li>
                        </ul>
                    </div>
                </div>
                <H2>レキコトEnglish Part１・Part２　サンプル</H2>
                <Image2_2 imgs={[
                    { img: Img35 },
                    {
                        img: {
                            above: Img36,
                            below: Img37,
                        }
                    }]} />
                <Image2 imgs={[
                    { img: Img38 },
                    { img: Img39 }]} />
                <div className="pb-4">
                    <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                        <ul>
                            <li className='p-1'>
                                ・「レキシとコトバの先生」と同じ人物で対応した、英語版テキスト
                            </li>
                            <li className='p-1'>
                                ・Part１・・・「レキシとコトバの先生 上巻」
                            </li>
                            <li className='p-1'>
                                ・Part２・・・「レキシとコトバの先生 下巻」に対応
                            </li>
                        </ul>
                    </div>
                </div>
            </Layout>
        </>
    )
}