import { useNavigate } from "react-router-dom"
import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H3, H4, H5 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'

import Img1 from '../img/sdgs-text/img1.jpeg'
import Img2 from '../img/sdgs-text/img2.jpeg'
import Img3 from '../img/sdgs-text/img3.jpeg'
import Img4 from '../img/sdgs-text/img4.jpeg'

import Banner1 from '../img/layout/banner1.jpeg'
import Banner2 from '../img/layout/banner2.jpeg'

export default function SdgsText() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <Layout>
                <H4>SDGs マイスター検定 問題集</H4>
                <div className="grid gap-2 sm:grid-cols-2">
                    <div>
                        <div className="p-2">
                            <H5>
                                社会人向け
                            </H5>
                            <div className="rounded-xl">
                                <a href="/pdf/leaflet_elemental.pdf" download >
                                    <img src={Img1} className="mb-3 cursor" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="p-2">
                            <H5>
                                学生向け
                            </H5>
                            <div className="rounded-xl">
                                <a href="/pdf/leaflet_juniorhigh.pdf" download >
                                    <img src={Img2} className="mb-3 cursor" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid gap-2 sm:grid-cols-1">
                    <div>
                        <div className="p-2">
                            <div className="rounded-xl">
                                <a href="/pdf/leaflet_highschool.pdf" download >
                                    <img src={Img3} className="mb-3 cursor" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <H5>
                    本書の特徴
                </H5>
                <div className="grid gap-2 sm:grid-cols-2">
                    <div>
                        <div className="p-2">
                            <div className="rounded-lg">
                                本書は、SDGsの基礎的な内容から、ビジネスや教育分野ですぐに
                                応用できるケーススタディや事例の紹介を備えています。
                                そのため,下記のような方々に特におすすめできる内容となっています。
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="p-2">
                            <div className="rounded-xl">
                                <img src={Img4} className="cursor" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <H5>
                    経営者の方
                </H5>
                <div className="grid gap-2 sm:grid-cols-1 pb-6">
                    <div>
                        <div className="p-2">
                            <div className="rounded-lg">
                                日本国内はもちろん,世界的にも認知度が増しており、特にビジネスシーンにおいては、常識となりつつあるSDGsの理念やゴール、具体的なターゲットを実践的に身に付けることができます。
                                経営戦略の策定や企業ビジョンの明確化に応用していくことで、競合他社との競争力を底上げするだけなく、ビジョンと同じとする他の経営者との協創にも必ずや役立つと考えます。
                            </div>
                        </div>
                    </div>
                </div>
                <H5>
                    企業担当者の方
                </H5>
                <div className="grid gap-2 sm:grid-cols-1 pb-6">
                    <div>
                        <div className="p-2">
                            <div className="rounded-lg">
                                国内の企業でもSDGsの達成をビジョンに掲げる企業が増えてきている中で、広報やプロモーションのご担当者様はもちろん、
                                営業やマーケティングの担当者様・すべての企業内の担当者様がSDGsの本質を理解し推進することで、企業内での活躍を促進できると考えます。
                            </div>
                        </div>
                    </div>
                </div>
                <H5>
                    教育者の方
                </H5>
                <div className="grid gap-2 sm:grid-cols-1 pb-6">
                    <div>
                        <div className="p-2">
                            <div className="rounded-lg">
                                学校教育や塾の教育現場でもSDGsに関連した教育の実施の重要性が高まっていくと予測されます。
                                実際に、令和2年度に小学校学習指導要領、令和3年度には中学校指導要領へ盛り込まれている状況となっており、
                                高校教育においても生徒自身が積極的に取り組む活動報告を目にする機会が多くなってきています。
                                そのような状況の中で、教える立場である教育者のみなさまが率先して身に付けることで、
                                子どもたちへ正しく、そして効率的に教えることができると考えます。
                            </div>
                        </div>
                    </div>
                </div>
                <H5>
                    大学生・大学院生の方
                </H5>
                <div className="grid gap-2 sm:grid-cols-1 pb-6">
                    <div>
                        <div className="p-2">
                            <div className="rounded-lg">
                                今後、就職を考えられている学生のみなさまにおいては、国内外の企業がSDGsの達成を意識して事業を推進していこうとしている現在の社会状況のなかで、
                                就職試験の際にご自身のアピールポイントの一つとしてSDGsの知識を身に付けたことに自信を持つことができると思います。
                                また、社会人となった後も企業内で仕事を行う際に、SDGsに関連した業務を行う可能性が非常に高いと予測されますので、
                                その際に事前知識を持った状態で臨めることは、必ずやみなさまの助けとなるでしょう。
                            </div>
                        </div>
                    </div>
                </div>
                <H5>
                    小・中・高校生の方
                </H5>
                <div className="grid gap-2 sm:grid-cols-1 pb-6">
                    <div>
                        <div className="p-2">
                            <div className="rounded-lg">
                                SDGsの内容には、非常に複雑で難しい内容もいくつか含まれていますが、そのほとんどが生活する中で身近に存在する問題ばかりです。
                                それらに対して問題意識を持つことで、将来の夢を描く際や直近の進路を定めていく時に、この学びは有効に働くと考えています。
                                また、持続可能な開発目標（SDGs）推進本部が主催する「ジャパンSDGsアワード」でも中高生を中心とした活動が表彰されているなど、
                                学びを通して実際に活動し、みなさんに次世代のSDGsの担い手となってほしいと考えています。
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid gap-2 sm:grid-cols-2">
                    <div>
                        <div className="p-2">
                            <div>
                                <div className="grid gap-2 lg:grid-cols-1">
                                    {[
                                        {
                                            img: Banner1,
                                            url: `/contact`
                                        },
                                        {
                                            img: Banner2,
                                            url: `/contact-kojin`
                                        }
                                    ].map((item, key) => (
                                        <div key={key}>
                                            <div>
                                                <div className="cursor" onClick={() => navigate(item.url)}>
                                                    <Image1 img={item.img} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="p-2">
                            <div>
                                <img src={Img4} className="mb-3" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}