import reactStringReplace from "react-string-replace"
import { useNavigate } from "react-router-dom";
import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'

import Img1 from '../img/koko-jyuken-ad/img1.jpeg'
import Img2 from '../img/koko-jyuken-ad/img2.jpeg'

import Banner1 from '../img/koko-jyuken-ad/banner1.png'
import Banner2 from '../img/koko-jyuken-ad/banner2.jpeg'
import Banner3 from '../img/koko-jyuken-ad/banner3.png'
import Banner4 from '../img/koko-jyuken-ad/banner4.jpeg'
import Banner5 from '../img/koko-jyuken-ad/banner5.jpeg'
import Banner6 from '../img/koko-jyuken-ad/banner6.png'
import Banner7 from '../img/koko-jyuken-ad/banner7.jpeg'
import Banner8 from '../img/koko-jyuken-ad/banner8.jpeg'
import Banner9 from '../img/koko-jyuken-ad/banner9.png'
import Banner10 from '../img/koko-jyuken-ad/banner10.jpeg'
import Banner11 from '../img/koko-jyuken-ad/banner11.jpeg'
import Banner12 from '../img/koko-jyuken-ad/banner12.jpeg'
import Banner13 from '../img/koko-jyuken-ad/banner13.jpeg'

export default function KokoJyukenAd() {
    const navigate = useNavigate()
    const regExp = /(https?:\/\/\S+)/g
    return (
        <>
            <Header />
            <Layout>
                <H4>【私学・学習塾対象】高校受験情報サイト 広告掲載 募集中！</H4>
                <Image1 img={Img1} />
                <Image1 img={Img2} />
                <div className="mb-3" onClick={() => navigate(`/contact2`)}>
                    <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >お問い合わせ</button>
                </div>
                <div className="grid gap-2 sm:grid-cols-1">
                    <div>
                        <div className="p-2">
                            <div className="px-4 py-3">
                                <ul>
                                    <li className='p-1 text-lg text-center font-bold'>
                                        高校受験を控える、多くの中学生・保護者がピンポイントで閲覧しています。
                                    </li>
                                    <li className='p-1 text-lg text-center font-bold'>
                                        貴塾の広告を掲載し、地域へのPRにお役立て下さい。
                                    </li>
                                    <li className='p-1 text-lg text-center font-bold'>
                                        運営は「マイルストーンコンサルティング」がパブリックな情報発信を行っています。
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <H2>
                    対象県（北海道・東北・関東４県・静岡・沖縄）
                </H2>
                <div className="flex flex-col sm:flex-row">
                    <div className="sm:w-1/3 p-1">
                        {reactStringReplace('https://www.hokkaido-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner1} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.akita-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner2} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.fukushima-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner3} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.gunma-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner4} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.shizuoka-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner5} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.okinawa-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner6} />
                            </a>
                        ))}
                    </div>
                    <div className="sm:w-1/3 p-1">
                        {reactStringReplace('https://www.aomori-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner7} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.miyagi-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner8} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.ibaraki-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner9} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.saitama-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner10} />
                            </a>
                        ))}
                    </div>
                    <div className="sm:w-1/3 p-1">
                        {reactStringReplace('https://www.iwate-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner11} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.yamagata-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner12} />
                            </a>
                        ))}
                        {reactStringReplace('https://www.tochigi-koko-jyuken.com/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <Image1 img={Banner13} />
                            </a>
                        ))}
                    </div>
                </div>
                <H2>
                    各県 高校受験情報サイト 特徴
                </H2>
                <div className="grid gap-2 sm:grid-cols-1">
                    <div>
                        <div className="p-2">
                            <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                                <ul>
                                    <li className='p-1'>
                                        ・高校入試、教育情報をパブリックなメディアとしての発信サイト
                                    </li>
                                    <li className='p-1'>
                                        ・県内の高校受験生・保護者・教育関係者向けのサイト
                                    </li>
                                    <li className='p-1'>
                                        ・スマートフォン対応。facebook・Twitter・LINE活用
                                    </li>
                                    <li className='p-1'>
                                        ・高校受験情報サイト ▶ 貴塾サイトへの誘導
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <H2>
                    広告掲載のメリット
                </H2>
                <div className="grid gap-2 sm:grid-cols-1">
                    <div>
                        <div className="p-2">
                            <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                                <ul>
                                    <li className='p-1'>
                                        ・県内全域のターゲット（高校受験生・保護者）に広く発信
                                    </li>
                                    <li className='p-1'>
                                        ・チラシ等と比較し、県内全域に安価で案内が可能
                                    </li>
                                    <li className='p-1'>
                                        ・戦略的に告知したい講座を案内
                                    </li>
                                    <li className='p-1'>
                                        ・高校受験情報サイトと貴塾HPのリンクによる相乗効果
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid gap-2 sm:grid-cols-1">
                    <div>
                        <div className="p-2">
                            <div className="px-4 py-3">
                                <ul>
                                    <li className='p-1 text-md text-center font-bold'>
                                        広告掲載にご興味のある塾様、下記ボタンよりお問い合わせ下さい。
                                    </li>
                                    <li className='p-1 text-md text-center font-bold'>
                                        Webサイトの掲載記事、アクセス件数、関連ソーシャルメディアなどご説明いたします。
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3" onClick={() => navigate(`/contact2`)}>
                    <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >お問い合わせ</button>
                </div>
            </Layout>
        </>
    )
}