export const Card2 = ({ datas }: { datas: any }) => {
  return (
    <div className="pb-4">
      <div className="grid gap-2 lg:grid-cols-2">
        {datas.map((data: any, key: any) => (
          <div key={key}>
            <div className="">
              <img src={data.img} className="object-scale-down h-48 w-96 mt-2 mb-2" alt="" />
            </div>
            <div className="white-space text-center">
              {data.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const Card3 = ({ datas }: { datas: any }) => {
  return (
    <div className="pb-4">
      <div className="grid gap-2 lg:grid-cols-3">
        {datas.map((data: any, key: any) => (
          <div key={key}>
            <div className="">
              <img src={data.img} className="object-scale-down h-48 w-96 mt-2 mb-2" alt="" />
            </div>
            <div className="white-space text-center">
              {data.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const Card4 = ({ datas }: { datas: any }) => {
  return (
    <div className="pb-4">
      <div className="grid gap-2 lg:grid-cols-4">
        {datas.map((data: any, key: any) => (
          <div key={key}>
            <div className="">
              <img src={data.img} className="object-scale-down h-48 w-96 mt-2 mb-2" alt="" />
            </div>
            <div className="white-space text-center">
              {data.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const Card5 = ({ datas }: { datas: any }) => {
  return (
    <div className="pb-4">
      <div className="grid gap-2 lg:grid-cols-5">
        {datas.map((data: any, key: any) => (
          <div key={key}>
            <div>
              <img src={data.img} className="w-full mt-2 mb-2" alt="" />
            </div>
            <div className="white-space text-center">
              {data.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

