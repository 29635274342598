import Header from './elements/Header'
import Layout from './elements/Layout'

import { H4 } from './elements/Heading'

import { useNavigate } from "react-router-dom";

export default function KokoPv() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <Layout>
                <H4>私立高校 学校紹介動画</H4>
                <div className="flex flex-col sm:flex-row">
                    <div className="sm:w-1/3 p-1">
                        <div className="text-3xl">
                            ・北海道
                        </div>
                        <div className="text-3xl">
                            ・青森県
                        </div>
                        <div className="text-3xl">
                            ・岩手県
                        </div>
                        <div className="text-3xl">
                            ・秋田県
                        </div>
                        <div className="text-3xl">
                            ・宮城県
                        </div>
                        <div className="text-3xl">
                            ・山形県
                        </div>
                        <div className="cursor text-3xl text-blue-500" onClick={() => navigate(`/koko-pv/tohoku`)}>
                            ・福島県
                        </div>
                    </div>
                    <div className="sm:w-1/3 p-1">
                        <div className="cursor text-3xl text-blue-500" onClick={() => navigate(`/koko-pv/kitakanto`)}>
                            ・茨城県
                        </div>
                        <div className="cursor text-3xl text-blue-500" onClick={() => navigate(`/koko-pv/kitakanto`)}>
                            ・栃木県
                        </div>
                        <div className="cursor text-3xl text-blue-500" onClick={() => navigate(`/koko-pv/kitakanto`)}>
                            ・群馬県
                        </div>
                        <div className="cursor text-3xl text-blue-500" onClick={() => navigate(`/koko-pv/shutoken`)}>
                            ・埼玉県
                        </div>
                        <div className="cursor text-3xl text-blue-500" onClick={() => navigate(`/koko-pv/shutoken`)}>
                            ・千葉県
                        </div>
                        <div className="cursor text-3xl text-blue-500" onClick={() => navigate(`/koko-pv/shutoken`)}>
                            ・東京都
                        </div>
                        <div className="text-3xl">
                            ・神奈川県
                        </div>
                    </div>
                    <div className="sm:w-1/3 p-1">
                        <div className="text-3xl">
                            ・静岡県
                        </div>
                        <div className="text-3xl">
                            ・沖縄県
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}