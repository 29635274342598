import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export const Slider1 = ({ slides }: { slides: any }) => {
  return (
    <div className="pb-4">
      <Carousel autoPlay={true} dynamicHeight={true} >
        {slides.map((slide: any) => (
          <img src={slide} className="w-full" alt="" />
        ))}
      </Carousel>
    </div>
  )
}

export const Slider2 = ({ arr }: { arr: any }) => {
  return (
    <div className="pb-4">
      <div className="flex flex-col sm:flex-row">
        {arr.map((slides: any, key: any) => (
          <div className="sm:w-1/2">
            <div key={key}>
              <Carousel autoPlay={true} dynamicHeight={true} >
                {slides.map((slide: any) => (
                  <img src={slide} className="w-full" alt="" />
                ))}
              </Carousel>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const Slider2WithContent = ({ arr }: { arr: any }) => {
  return (
    <div className="pb-4">
      <div className="flex flex-col sm:flex-row">
        {arr.map((data: any, key: any) => (
          <div className="sm:w-1/2 p-2">
            <div key={key}>
              <Carousel autoPlay={true} dynamicHeight={true} >
                {data.slide.map((slide: any) => (
                  <img src={slide} className="w-full" alt="" />
                ))}
              </Carousel>
              <div className="white-space text-center text-xl text-lime-500 font-bold">
                {data.title}
              </div>
              <div className="white-space text-center pb-4">
                {data.subtitle}
              </div>
              <div className="white-space">
                {data.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
