import React      from 'react'
import { Routes } from "react-router-dom"
import { Route }  from "react-router-dom"

import Home from './components/Home'
import SdgsText from './components/SdgsText'
import Future from './components/Future'
import CheerStudy from './components/CheerStudy'
import SakurasakuNote from './components/SakurasakuNote'
import Hensyu from './components/Hensyu'
import KokoJyukenAd from './components/KokoJyukenAd'
import KokoPv from './components/KokoPv'
import KokoPvTohoku from './components/KokoPvTohoku'
import KokoPvKitakanto from './components/KokoPvKitakanto'
import KokoPvShutoken from './components/KokoPvShutoken'
import MeChira from './components/MeChira'
import OneRead from './components/OneRead'
import Recruit from './components/Recruit'
import DayTra from './components/DayTra'
import SdgsWork from './components/SdgsWork'
import Rekikoto from './components/Rekikoto'
import PrograDojo from './components/PrograDojo'
import Miyapickles from './components/Miyapickles'
import BosaiFreebox from './components/BosaiFreebox'
import Contact2 from './components/Contact2'
import Contact from './components/Contact'
import ContactKojin from './components/ContactKojin'
import Company from './components/Company'
import Blog from './components/Blog'
import Privacy from './components/Privacy'
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/"                   element={<Home />}            />
        <Route path="/sdgs-text"          element={<SdgsText />}        />
        <Route path="/future"             element={<Future />}          />
        <Route path="/cheerstudy"         element={<CheerStudy />}      />
        <Route path="/sakurasakunote"     element={<SakurasakuNote />}  />
        <Route path="/hensyu"             element={<Hensyu />}          />
        <Route path="/koko-jyuken-ad"     element={<KokoJyukenAd />}    />
        <Route path="/koko-pv"            element={<KokoPv />}          />
        <Route path="/koko-pv/tohoku"     element={<KokoPvTohoku />}  />
        <Route path="/koko-pv/kitakanto"  element={<KokoPvKitakanto />} />
        <Route path="/koko-pv/shutoken"   element={<KokoPvShutoken />}  />
        <Route path="/me-chira"           element={<MeChira />}         />
        <Route path="/one-read"           element={<OneRead />}         />
        <Route path="/recruit"            element={<Recruit />}         />
        <Route path="/day-tra"            element={<DayTra />}          />
        <Route path="/sdgs-work"          element={<SdgsWork />}        />
        <Route path="/rekikoto"           element={<Rekikoto />}        />
        <Route path="/progra-dojo"        element={<PrograDojo />}      />
        <Route path="/miyapickles"        element={<Miyapickles />}     />
        <Route path="/bosai-freebox"      element={<BosaiFreebox />}    />
        <Route path="/contact2"           element={<Contact2 />}        />
        <Route path="/contact"            element={<Contact />}         />
        <Route path="/contact-kojin"      element={<ContactKojin />}    />
        <Route path="/company"            element={<Company />}         />
        <Route path="/blog"               element={<Blog />}            />
        <Route path="/privacy"            element={<Privacy />}         />
      </Routes>
    </div>
  )
}

export default App
