import Logo from '../../img/logo_small.png'

export const H1 = (props: any) => {
  return (
    <div className="pb-4">
      <div className="inline-flex items-center space-x-2">
        <div>
          <img src={Logo} alt="アイコン" className="h-10 w-10 cursor" />
        </div>
        <div className="text-2xl font-bold">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export const H2 = (props: any) => {
  return (
    <div className="pb-4">
      <div className="divide-y divide-dashed divide-red-500 pt-3 pb-3">
        <div className="text-lg text-gray-600">
          {props.children}
        </div>
        <div>

        </div>
      </div>
    </div>
  )
}

export const H3 = (props: any) => {
  return (
    <div className="pb-4">
      <div className="inline-flex items-center space-x-2">
        <div>
          <div className="text-3xl text-bold text-gray-600">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export const H4 = (props: any) => {
  return (
    <div className="pb-4">
      <div className="inline-flex items-center space-x-2">
        <div className="text-xl border-l-8 border-orange-500 pl-2">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export const H5 = (props: any) => {
  return (
    <div className="pb-4">
      <div className="inline-flex items-center space-x-2">
        <div>
          <img src={Logo} alt="アイコン" className="h-10 w-10 cursor" />
        </div>
        <div className="text-xl">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export const H6 = (props: any) => {
  return (
    <div className="pb-4">
      <div className="pt-3 pb-3">
        <div className="text-3xl font-bold text-orange-500">
          {props.children}
        </div>
        <div>

        </div>
      </div>
    </div>
  )
}