import reactStringReplace from "react-string-replace"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom";
import { ScrollTop } from '../utils/ScrollTop'
import { H2 } from "./Heading"
import Footer from "./Footer"
import Img1 from '../../img/layout/banner1.jpeg'
import Img2 from '../../img/layout/banner2.jpeg'
import Img3 from '../../img/layout/banner3.jpeg'
import Img4 from '../../img/layout/banner4.jpeg'
import Img5 from '../../img/layout/banner5.png'
import Img6 from '../../img/layout/banner6.jpeg'
import Img7 from '../../img/layout/banner7.jpeg'
import Img8 from '../../img/layout/banner8.jpeg'
import Img9 from '../../img/layout/banner9.gif'
import Img10 from '../../img/layout/banner10.png'
import Img11 from '../../img/layout/banner11.png'
import SakurraCco from "../../img/home/sakurra-cco.jpg";

export default function Layout(props: any) {
  const regExp = /(https?:\/\/\S+)/g
  const navigate = useNavigate()
  ScrollTop(useLocation())
  return (
    <div className="container mx-auto">
      <div className="lg:w-11/12 mx-auto">
        <div className="lg:w-5/6 p-3 mx-auto">

          <div className="md:inline-block">
            <div className="flex flex-col sm:flex-row p-2">
              <div className="sm:w-3/4 p-2">
                <div key="hoge">{props.children}</div>
              </div>
              <div className="sm:w-1/4 p-2">
                <div className="pl-2 pb-2">
                  {reactStringReplace('https://home.tsuku2.jp/storeDetail.php?scd=0000263709', regExp, (match, i) => (
                    <a href={match} target="_blank" rel="noopener noreferrer">
                      <img src={SakurraCco} className="w-full m-auto mb-2 cursor" alt="" />
                    </a>
                  ))}
                  <img src={Img1} className="w-full m-auto mb-2 cursor" alt="" onClick={() => navigate(`/contact`)} />
                  <img src={Img2} className="w-full m-auto mb-2 cursor" alt="" onClick={() => navigate(`/contact-kojin`)} />
                  <img src={Img3} className="w-full m-auto mb-2 cursor" alt="" onClick={() => navigate(`/company`)} />
                  <img src={Img4} className="w-full m-auto mb-2 cursor" alt="" onClick={() => navigate(`/blog`)} />
                  {reactStringReplace('https://ws.formzu.net/fgen/S88786752/', regExp, (match, i) => (
                    <a href={match} target="_blank" rel="noopener noreferrer">
                      <img src={Img5} className="w-full m-auto mb-2 cursor" alt="" />
                    </a>
                  ))}
                  {reactStringReplace('https://www.jyda.jp/', regExp, (match, i) => (
                    <a href={match} target="_blank" rel="noopener noreferrer">
                      <img src={Img6} className="w-full m-auto mb-2 cursor" alt="" />
                    </a>
                  ))}
                  {reactStringReplace('https://kanagawa.doyu.jp/', regExp, (match, i) => (
                    <a href={match} target="_blank" rel="noopener noreferrer">
                      <img src={Img7} className="w-full m-auto mb-2 cursor" alt="" />
                    </a>
                  ))}
                  {reactStringReplace('https://www.tokyo-cci.or.jp/', regExp, (match, i) => (
                    <a href={match} target="_blank" rel="noopener noreferrer">
                      <img src={Img8} className="w-full m-auto mb-2 cursor" alt="" />
                    </a>
                  ))}
                  {reactStringReplace('http://www.juku-kyoiku.com/', regExp, (match, i) => (
                    <a href={match} target="_blank" rel="noopener noreferrer">
                      <img src={Img9} className="w-full m-auto mb-2 cursor" alt="" />
                    </a>
                  ))}
                  {reactStringReplace('https://www.sokunousokudoku.net/', regExp, (match, i) => (
                    <a href={match} target="_blank" rel="noopener noreferrer">
                      <img src={Img10} className="w-full m-auto mb-2 cursor" alt="" />
                    </a>
                  ))}

                  <H2>高校受験情報サイト</H2>
                  <span>受験に関するお役立ち情報を掲載！</span>

                  <div className="grid gap-2 lg:grid-cols-3">
                    {[
                      { title: "▶︎ 北海道", url: 'https://www.hokkaido-koko-jyuken.com/' },
                      { title: "▶︎ 青森県", url: 'https://www.aomori-koko-jyuken.com/' },
                      { title: "▶︎ 岩手県", url: 'https://www.iwate-koko-jyuken.com/' },
                      { title: "▶︎ 秋田県", url: 'https://www.akita-koko-jyuken.com/' },
                      { title: "▶︎ 宮城県", url: 'https://www.miyagi-koko-jyuken.com/' },
                      { title: "▶︎ 山形県", url: 'https://www.yamagata-koko-jyuken.com/' },
                      { title: "▶︎ 福島県", url: 'https://www.fukushima-koko-jyuken.com/' },
                      { title: "▶︎ 茨城県", url: 'https://www.ibaraki-koko-jyuken.com/' },
                      { title: "▶︎ 栃木県", url: 'https://www.tochigi-koko-jyuken.com/' },
                      { title: "▶︎ 群馬県", url: 'https://www.gunma-koko-jyuken.com/' },
                      { title: "▶︎ 埼玉県", url: 'https://www.saitama-koko-jyuken.com/' },
                      { title: "▶︎ 千葉県", url: 'https://www.chiba-koko-jyuken.com/' },
                      { title: "▶︎ 静岡県", url: 'https://www.shizuoka-koko-jyuken.com/' },
                      { title: "▶︎ 沖縄県", url: 'https://www.okinawa-koko-jyuken.com/' }
                    ].map((items: any) => (
                      <div key={items.title}>
                        <div className="cursor text-sm text-center text-green-800 border border-green-800 rounded-md p-1">
                          {items.title.split('\n').map((item: any) => {
                            return (
                              <div key={item}>
                                {reactStringReplace(items.url, regExp, (match, i) => (
                                  <a href={match} target="_blank" rel="noopener noreferrer">{item}</a>
                                ))}
                                <br />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="pt-4 pb-4">
                    <div className="text-md">株式会社 Terra-Cco（テラッコ）</div>
                    <div className="text-sm">〒140-0011 品川区東大井5丁目2-7 磯部ビル2-202</div>
                  </div>

                  <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fterracco.inc%2F&amp;tabs=timeline&amp;width=300&amp;height=1000&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId=331345546940325"
                    width="100%"
                    height="1000"
                    scrolling="no"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

                  <div className="pt-4 pb-4">
                    {reactStringReplace('https://www.mariage-gift.com/', regExp, (match, i) => (
                      <a href={match} target="_blank" rel="noopener noreferrer">
                        <img src={Img11} className="w-full m-auto mb-2 cursor" />
                      </a>
                    ))}
                  </div>

                </div>
              </div>
            </div>
          </div>

          <Footer />

        </div>
      </div>
    </div>
  )
}
