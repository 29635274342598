import { useNavigate } from "react-router-dom"

import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'
import { Youtube1 } from './elements/Youtube'

import Img1 from '../img/me-chira/img1.jpeg'
import Img2 from '../img/me-chira/img2.jpeg'
import Img3 from '../img/me-chira/img3.jpeg'
import Img4 from '../img/me-chira/img4.jpeg'

export default function KokoJyukenAd() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <Layout>
                <H4>心に残る資料「メーチラ」</H4>
                <H2>メーチラ（私立学校向け）</H2>
                <Image2 imgs={[
                    { img: Img1 },
                    { img: Img2 },
                ]} />
                <div className="mb-3 cursor" onClick={() => navigate(`/contact`)}>
                    <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >お問い合わせ</button>
                </div>
                <H2>メーチラ（学習塾向け）</H2>
                <Image2 imgs={[
                    { img: Img3 },
                    { img: Img4 },
                ]} />
                <div className="mb-3 cursor" onClick={() => navigate(`/contact`)}>
                    <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >お問い合わせ</button>
                </div>
                <H2>動画サンプル（１分間）</H2>
                <Youtube1 urls={[
                    { id: "q3pt8ZOXLNE" }
                ]} height={'500px'} />
                <div className="mb-3 cursor" onClick={() => navigate(`/contact`)}>
                    <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >お問い合わせ</button>
                </div>
            </Layout>
        </>
    )
}