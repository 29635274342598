import reactStringReplace from "react-string-replace"
import { useNavigate } from "react-router-dom"
import Header from './elements/Header'
import Layout from './elements/Layout'
import { Slider2WithContent } from './elements/Slider'
import { Youtube1, Youtube2 } from './elements/Youtube'
import { H2, H3, H4 } from './elements/Heading'
import { Slider1, Slider2 } from './elements/Slider'
import { Image1, Image2 } from './elements/Image'

import Img1 from '../img/progra-dojo/img1.jpeg'
import Img2 from '../img/progra-dojo/img2.jpeg'
import Img3 from '../img/progra-dojo/img3.jpeg'
import Img4 from '../img/progra-dojo/img4.jpeg'
import Img5 from '../img/progra-dojo/img5.png'
import Img6 from '../img/progra-dojo/img6.png'
import Img7 from '../img/progra-dojo/img7.jpeg'
import Img8 from '../img/progra-dojo/img8.jpeg'
import Img9 from '../img/progra-dojo/img9.jpeg'
import Img10 from '../img/progra-dojo/img10.png'
import Img11 from '../img/progra-dojo/img11.png'

import PrograBook1 from '../img/progra-dojo/progra-book/1.jpeg'
import PrograBook2 from '../img/progra-dojo/progra-book/2.jpeg'
import PrograBook3 from '../img/progra-dojo/progra-book/3.jpeg'
import PrograBook4 from '../img/progra-dojo/progra-book/4.jpeg'
import PrograBook5 from '../img/progra-dojo/progra-book/5.jpeg'
import PrograBook6 from '../img/progra-dojo/progra-book/6.jpeg'
import PrograBook7 from '../img/progra-dojo/progra-book/7.jpeg'
import PrograBook8 from '../img/progra-dojo/progra-book/8.jpeg'
import PrograBook9 from '../img/progra-dojo/progra-book/9.jpeg'
import PrograBook10 from '../img/progra-dojo/progra-book/10.jpeg'

import PrograDojo1 from '../img/progra-dojo/progra-dojo/1.jpeg'
import PrograDojo2 from '../img/progra-dojo/progra-dojo/2.jpeg'
import PrograDojo3 from '../img/progra-dojo/progra-dojo/3.jpeg'
import PrograDojo4 from '../img/progra-dojo/progra-dojo/4.jpeg'
import PrograDojo5 from '../img/progra-dojo/progra-dojo/5.jpeg'
import PrograDojo6 from '../img/progra-dojo/progra-dojo/6.jpeg'
import PrograDojo7 from '../img/progra-dojo/progra-dojo/7.jpeg'
import PrograDojo8 from '../img/progra-dojo/progra-dojo/8.jpeg'
import PrograDojo9 from '../img/progra-dojo/progra-dojo/9.jpeg'
import PrograDojo10 from '../img/progra-dojo/progra-dojo/10.jpeg'

import CompanyInfo1 from '../img/progra-dojo/company-info/1.jpeg'
import CompanyInfo2 from '../img/progra-dojo/company-info/2.jpeg'
import CompanyInfo3 from '../img/progra-dojo/company-info/3.jpeg'
import CompanyInfo4 from '../img/progra-dojo/company-info/4.jpeg'
import CompanyInfo5 from '../img/progra-dojo/company-info/5.jpeg'
import CompanyInfo6 from '../img/progra-dojo/company-info/6.jpeg'
import CompanyInfo7 from '../img/progra-dojo/company-info/7.jpeg'

export default function PrograDojo() {
    const navigate = useNavigate()
    const regExp = /(https?:\/\/\S+)/g
    return (
        <>
            <Header />
            <Layout>
                <H4>
                    【学習塾・私学】様向け プログラ道場 思考力パズル+プログラミング
                </H4>
                <Image2 imgs={[
                    { img: Img10 },
                    { img: Img11 }
                ]} />
                <Image2 imgs={[
                    { img: Img1 },
                    { img: Img2 }
                ]} />
                <div className="mb-3">
                    <a href="/pdf/leaflet_rekikoto.pdf" download >
                        <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >プログラ道場・ダウンロード</button>
                    </a>
                </div>
                <Image2 imgs={[
                    { img: Img3 },
                    { img: Img4 }
                ]} />
                <Youtube1 urls={[
                    { id: "zV6CJ6i6m9k" }
                ]} height={'500px'} />
                <Youtube2 urls={[
                    { id: "Lt0OTTgh0uk" },
                    { id: "M1WYvjwH08s" },]}
                    height={'300px'} />

                <Slider2WithContent arr={[
                    {
                        title:
                            null,
                        subtitle:
                            null,
                        description:
                            null,
                        slide: [
                            PrograBook1,
                            PrograBook2,
                            // PrograBook3,
                            // PrograBook4,
                            // PrograBook5,
                            // PrograBook6,
                            // PrograBook7,
                            // PrograBook8,
                            // PrograBook9,
                            // PrograBook10,
                        ],
                    },
                    {
                        title:
                            null,
                        subtitle:
                            null,
                        description:
                            null,
                        slide: [
                            PrograDojo1,
                            PrograDojo2,
                            PrograDojo3,
                            PrograDojo4,
                            PrograDojo5,
                            PrograDojo6,
                            // PrograDojo7,
                            // PrograDojo8,
                            // PrograDojo9,
                            // PrograDojo10,
                        ],
                    },
                ]} />

                <H4>
                    開発元：プログラぶっく
                </H4>
                <Slider1 slides={[
                    CompanyInfo1,
                    CompanyInfo2,
                    CompanyInfo3,
                    CompanyInfo4,
                    CompanyInfo5,
                    CompanyInfo6,
                    CompanyInfo7,
                ]} />
                <H2>
                    インタビュー・記事掲載
                </H2>
                <div>
                    {reactStringReplace('https://toyokeizai.net/articles/-/320862?display=b', regExp, (match, i) => (
                        <a href={match} target="_blank" rel="noopener noreferrer">
                            <Image1 img={Img6} />
                        </a>
                    ))}
                </div>
                <div className="grid gap-2 lg:grid-cols-2">
                    {[
                        {
                            img: Img8,
                            url: `/contact`
                        },
                        {
                            img: Img9,
                            url: `/contact-kojin`
                        }
                    ].map((item, key) => (
                        <div key={key} className="cursor" onClick={() => navigate(item.url)}>
                            <Image1 img={item.img} />
                        </div>
                    ))}
                </div>
            </Layout>
        </>
    )
}