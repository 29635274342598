import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'

import Img1 from '../img/one-read/img1.jpeg'
import Img2 from '../img/one-read/img2.jpeg'
import Img3 from '../img/one-read/img3.jpeg'
import Img4 from '../img/one-read/img4.jpeg'
import Img5 from '../img/one-read/img5.jpeg'
import Img6 from '../img/one-read/img6.jpeg'
import Img7 from '../img/one-read/img7.jpeg'
import Img8 from '../img/one-read/img8.jpeg'


export default function OneRead() {
    return (
        <>
            <Header />
            <Layout>
                <H4>One Read（スクール・学習塾管理システム）</H4>
                <Image1 img={Img1} />
                <Image1 img={Img2} />
                <Image1 img={Img3} />
                <Image1 img={Img4} />
                <Image1 img={Img5} />
                <Image1 img={Img6} />
                <Image1 img={Img7} />
                <Image1 img={Img8} />
            </Layout>
        </>
    )
}