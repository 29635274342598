import { useNavigate } from "react-router-dom"
import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'

import Img1 from '../img/sdgs-work/img1.jpeg'
import Img2 from '../img/sdgs-work/img2.jpeg'
import Img3 from '../img/sdgs-work/img3.jpeg'
import Img4 from '../img/sdgs-work/img4.jpeg'
import Img5 from '../img/sdgs-work/img5.jpeg'
import Img6 from '../img/sdgs-work/img6.jpeg'

export default function SdgsWork() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <Layout>
                <H4>SDGs から学ぶ 知識と探究のワーク</H4>
                <Image2 imgs={[
                    { img: Img1 },
                    { img: Img2 }]} />
                <H2>本書の特色</H2>
                <div className="flex flex-col sm:flex-row mt-5 mb-5">
                    <div className="sm:w-2/3">
                        <ul>
                            <li className="m-3 text-md">
                                「SDGs から学ぶ知識と探究のワーク」のテーマは”つなぐ”です。
                                社会生活と学びをつなぐこと、私たちと世界をつなぐこと。
                                未来をつなぐみなさんへどうぞ未来を創る第一歩としてお役立て下さい。
                            </li>
                        </ul>
                    </div>
                    <div className="sm:w-1/3 sm:pl-6 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                        <img src={Img3} className=" w-full object-cover object-center" alt="" />
                    </div>
                </div>
                <div className="px-4 py-3">
                    ■Ａ４版 全80ページ　フルカラー　価格￥1,650（税込）
                </div>
                <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                    <ul>
                        <li className='p-1'>■ 特色１：SDGs をテーマに探究や総合の学習教材として</li>
                        <li className='p-1'>■ 特色２：身近に見聞きするキーワードを豊富に取り入れた教材</li>
                        <li className='p-1'>■ 特色３：自分の意見や考えを書き込める、スペースたっぷり</li>
                        <li className='p-1'>■ 特色４：小論文や入試対策の「記述力」の養成に</li>
                    </ul>
                </div>
                <H2>本書の構成</H2>
                <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                    <ul>
                        <li className='p-1'>■ 前半は、SDGs17のゴール、169のターゲットの適語補充問題。 調べることで、理解を深めていくことができます。 知っておきたいことや覚えていきたい語句・数値など穴埋めや記号選択などの 問題に取り組みながら理解してもらうことができます。</li>
                        <li className='p-1'>■ 中盤のワークでは私たちができる「取り組み」をテーマに調べ学習や プレゼンテーションの文の作成、考察など「思考力」や「記述力」「表現力」などの トレーニングに使えるページです。</li>
                        <li className='p-1'>■ 後半のワークでは企業の実際の取組み事例を元に様々な角度から考える、調べる、まとめる、などの トレーニングを行いながら、自分ごととしてより深く思考するページです。</li>
                    </ul>
                </div>
                <div className="grid gap-2 sm:grid-cols-2">
                    <div>
                        <div className="p-2">
                            <div>
                                <div className="grid gap-2 lg:grid-cols-1">
                                    {[
                                        {
                                            img: Img4,
                                            url: `/contact`
                                        },
                                        {
                                            img: Img5,
                                            url: `/contact-kojin`
                                        }
                                    ].map((item, key) => (
                                        <div key={key}>
                                            <div>
                                                <div className="cursor" onClick={() => navigate(item.url)}>
                                                    <Image1 img={item.img} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="p-2">
                            <div>
                                <img src={Img6} className="mb-3" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}