import reactStringReplace from "react-string-replace"
import { useNavigate } from "react-router-dom"

import Header from './elements/Header'
import Layout from './elements/Layout'

import { Image1, Image2 } from './elements/Image'
import { Slider1 } from './elements/Slider'
import { H1, H2, H3 } from './elements/Heading'

import Swiper1 from '../img/home/carousel1.png'
import Swiper2 from '../img/home/carousel2.png'
import Swiper3 from '../img/home/carousel3.png'
import Swiper4 from '../img/home/carousel4.png'
import Swiper5 from '../img/home/carousel5.png'
import Swiper6 from '../img/home/carousel6.png'
import Swiper7 from '../img/home/carousel7.png'
import Swiper8 from '../img/home/carousel8.png'
import Swiper9 from '../img/home/carousel9.png'
import Swiper10 from '../img/home/carousel10.png'

import Img1 from '../img/home/img1.png'
import Img2 from '../img/home/img2.png'
import Img3 from '../img/home/img3.png'
import Img4 from '../img/home/img4.png'
import Img5 from '../img/home/img5.png'
import Img6 from '../img/home/img6.png'
import Img7 from '../img/home/img7.jpeg'
import Img8 from '../img/home/img8.jpeg'
import Img9 from '../img/home/img9.png'

import Banner1 from '../img/layout/banner1.jpeg'
import Banner2 from '../img/layout/banner2.jpeg'

import Material1 from '../img/home/material1.png'
import Material2 from '../img/home/material2.png'
import Material3 from '../img/home/material3.png'
import Material4 from '../img/home/material4.png'
import Material5 from '../img/home/material5.png'
import Material6 from '../img/home/material6.png'

import Stationery1 from '../img/home/stationery1.png'
import Stationery2 from '../img/home/stationery2.png'

import Support1 from '../img/home/support1.png'
import Support2 from '../img/home/support2.png'
import Support3 from '../img/home/support3.png'

import SakurraCco from '../img/home/sakurra-cco.jpg'

export default function Home() {
  const regExp = /(https?:\/\/\S+)/g
  const navigate = useNavigate()
  const slides = [
    Swiper10,
    Swiper1,
    Swiper2,
    Swiper3,
    Swiper4,
    Swiper5,
    Swiper6,
    Swiper7,
    Swiper8,
    Swiper9,
  ]
  return (
    <>
      <Header />
      <Layout>
        <Slider1 slides={slides} />
        <H3>さくらッコオンラインショップ</H3>
        <div>
          {reactStringReplace('https://home.tsuku2.jp/storeDetail.php?scd=0000263709', regExp, (match, i) => (
            <a href={match} target="_blank" rel="noopener noreferrer">
              <Image1 img={SakurraCco} />
            </a>
          ))}
        </div>
        <H3>教育コンテンツ</H3>
        <div>
          {[
            {
              img: Img1,
              url: `./progra-dojo`
            },
          ].map((item, key) => (
            <div key={key} className="cursor" onClick={() => navigate(item.url)}>
              <Image1 img={item.img} />
            </div>
          ))}
        </div>
        <H3>教材・書籍</H3>
        <div className="grid gap-2 lg:grid-cols-3">
          {[
            {
              img: Material1,
              url: `./rekikoto`
            },
            {
              img: Material2,
              url: `./sdgs-text`
            },
            {
              img: Material3,
              url: `./sdgs-work`
            },
            {
              img: Material4,
              url: `./day-tra`
            },
            {
              img: Material5,
              url: `./future`
            },
            {
              img: Material6,
              url: `./cheerstudy`
            },
          ].map((item, key) => (
            <div key={key} className="cursor" onClick={() => navigate(item.url)}>
              <Image1 img={item.img} />
            </div>
          ))}
        </div>
        <H3>文具</H3>
        <div className="grid gap-2 lg:grid-cols-3">
          {[
            {
              img: Stationery1,
              url: `./sakurasakunote`
            },
            {
              img: Stationery2,
              url: `./hensyu`
            }
          ].map((item, key) => (
            <div key={key} className="cursor" onClick={() => navigate(item.url)}>
              <Image1 img={item.img} />
            </div>
          ))}
        </div>
        <div className="grid gap-2 lg:grid-cols-2">
          {[
            {
              img: Banner1,
              url: `./contact`
            },
            {
              img: Banner2,
              url: `./contact-kojin`
            }
          ].map((item, key) => (
            <div key={key} className="cursor" onClick={() => navigate(item.url)}>
              <Image1 img={item.img} />
            </div>
          ))}
        </div>
        <H3>広報サポート</H3>
        <div>
          {[
            {
              img: Img2,
              url: `./koko-jyuken-ad`
            },
            {
              img: Img3,
              url: `./koko-pv`
            },
            {
              img: Img4,
              url: `./me-chira`
            },
            {
              img: Img5,
              url: `./blog`
            },
          ].map((item, key) => (
            <div key={key} className="cursor" onClick={() => navigate(item.url)}>
              <Image1 img={item.img} />
            </div>
          ))}
        </div>
        <H3>環境サポート</H3>
        <div className="grid gap-2 lg:grid-cols-3">
          {[
            {
              img: Support1,
              url: `./one-read`
            },
            {
              img: Support2,
              url: `./bosai-freebox`
            },
            {
              img: Support3,
              url: `./bosai-freebox`
            },
          ].map((item, key) => (
            <div key={key} className="cursor" onClick={() => navigate(item.url)}>
              <Image1 img={item.img} />
            </div>
          ))}
        </div>
        <H3>食育サポート</H3>
        <div>
          {[
            {
              img: Img6,
              url: `./miyapickles`
            },
          ].map((item, key) => (
            <div key={key} className="cursor" onClick={() => navigate(item.url)}>
              <Image1 img={item.img} />
            </div>
          ))}
        </div>
        <H3>文化事業部</H3>
        <div>
          {reactStringReplace('https://www.terracco-club.jp/', regExp, (match, i) => (
            <a href={match} target="_blank" rel="noopener noreferrer">
              <Image1 img={Img7} />
            </a>
          ))}
          {reactStringReplace('https://www.business-plus.net/interview/2010/k5857.html', regExp, (match, i) => (
            <a href={match} target="_blank" rel="noopener noreferrer">
              <Image1 img={Img8} />
            </a>
          ))}
          <div className="cursor" onClick={() => navigate(`./recruit`)}>
            <Image1 img={Img9} />
          </div>
        </div>
      </Layout>
    </>
  )
}
