import { useNavigate } from "react-router-dom";
import Header from './elements/Header'
import Layout from './elements/Layout'
import { H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'

import Img1 from '../img/daytra/img1.jpeg'
import Img2 from '../img/daytra/img2.jpeg'
import Img3 from '../img/daytra/img3.jpeg'
import Img4 from '../img/daytra/img4.jpeg'
import Img5 from '../img/daytra/img5.jpeg'
import Img6 from '../img/daytra/img6.jpeg'
import Img7 from '../img/daytra/img7.jpeg'
import Img8 from '../img/daytra/img8.jpeg'
import Img9 from '../img/daytra/img9.jpeg'
import Img10 from '../img/daytra/img10.jpeg'
import Img11 from '../img/daytra/img11.jpeg'
import Img12 from '../img/daytra/img12.jpeg'
import Img13 from '../img/daytra/img13.jpeg'
import Img14 from '../img/daytra/img14.jpeg'

import Img15 from '../img/daytra/img15.jpeg'
import Img16 from '../img/daytra/img16.jpeg'
import Img17 from '../img/daytra/img17.jpeg'
import Img18 from '../img/daytra/img18.jpeg'
import Img19 from '../img/daytra/img19.jpeg'
import Img20 from '../img/daytra/img20.jpeg'

export default function DayTra() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <Layout>
                <H4>
                    脳力づくり 始めよう! 知育算数 Day トレ
                </H4>
                <Image1 img={Img1} />
                <Image2 imgs={[
                    { img: Img2 },
                    { img: Img3 }]} />
                <div className="grid gap-2 lg:grid-cols-2">
                    {[
                        {
                            img: Img4,
                            url: `/contact`
                        },
                        {
                            img: Img5,
                            url: `/contact-kojin`
                        }
                    ].map((item, key) => (
                        <div key={key} className="cursor" onClick={() => navigate(item.url)}>
                            <Image1 img={item.img} />
                        </div>
                    ))}
                </div>

                <Image2 imgs={[
                    { img: Img6 },
                    { img: Img7 }]} />
                <H4>
                    Ⅰ Day トレ
                </H4>
                <Image3 imgs={[
                    { above: Img8, bottom: Img11, title: 'Part１（小1準備生～小1）' },
                    { above: Img9, bottom: Img12, title: 'Part２（小2～）' },
                    { above: Img10, bottom: Img13, title: 'Part３（小2・小3～）' }]} />
                <div className="grid gap-2 lg:grid-cols-2">
                    {[
                        {
                            img: Img4,
                            url: `/contact`
                        },
                        {
                            img: Img5,
                            url: `/contact-kojin`
                        }
                    ].map((item, key) => (
                        <div key={key} className="cursor" onClick={() => navigate(item.url)}>
                            <Image1 img={item.img} />
                        </div>
                    ))}
                </div>
                <H4>
                    Ⅱ Day トレ
                </H4>
                <Image3 imgs={[
                    { above: Img15, bottom: Img18, title: 'Part１（小2～）' },
                    { above: Img16, bottom: Img19, title: 'Part２（小2～）' },
                    { above: Img17, bottom: Img20, title: 'Part３（小2～）' }]} />
                <div className="grid gap-2 lg:grid-cols-2">
                    {[
                        {
                            img: Img4,
                            url: `/contact`
                        },
                        {
                            img: Img5,
                            url: `/contact-kojin`
                        }
                    ].map((item, key) => (
                        <div key={key} className="cursor" onClick={() => navigate(item.url)}>
                            <Image1 img={item.img} />
                        </div>
                    ))}
                </div>
                <H4>Day トレ 著者</H4>
                <div className="flex flex-col sm:flex-row mt-5 mb-5">
                    <div className="sm:w-1/3">
                        <img src={Img14} className=" w-full object-cover object-center" alt="" />
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                        <ul>
                            <li className="m-3 text-2xl text-green-700">
                                安藤 伸江 代表
                            </li>
                            <li className="m-3 text-xl text-green-700">
                                株式会社 希教育（<a href="http://jukutown.com/akatsuka/" className="underline">スクールのぞみ</a>）
                            </li>
                            <li className="m-3 text-2xl text-orange-500">
                                プロフィール
                            </li>
                            <li className="text-md">
                                ・進学予備校で教務長職に就く
                            </li>
                            <li className="text-md">
                                ・1989年 株式会社伸学社（伸学舎）設立取締役就任
                            </li>
                            <li className="text-md">
                                ・1995年 株式会社希教育（スクールのぞみ）設立
                            </li>
                            <li className="text-md">
                                ・低学年の教材開発に着手
                            </li>
                            <li className="text-md">
                                ・のぞみメソッドの確立
                            </li>
                            <li className="text-md">
                                ・各種研修会のパネリスト
                            </li>
                            <li className="text-md">
                                ・速読教務力アップセミナー講師兼任
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="m-3 text-2xl text-orange-500">
                    開発教材
                </div>
                <div className="p-3">
                    <ul>
                        <li className="text-md">
                            ・のぞみメソッド
                        </li>
                        <li className="text-md">
                            ・おはなし366
                        </li>
                        <li className="text-md">
                            ・みんなの伝記
                        </li>
                        <li className="text-md">
                            ・知育Dayトレ
                        </li>
                        <li className="text-md">
                            ・算数Dayトレ
                        </li>
                        <li className="text-md">
                            ・数学Dayトレ
                        </li>
                    </ul>
                </div>
                <div className="m-3 text-2xl text-orange-500">
                    Dayトレは、こうして誕生しました。
                </div>
                <div className="p-3">
                    <ul>
                        <li className="text-md mb-6">
                            Day トレのシステムが考案され、学習指導のプログラムとして導入して早や30年余りになろうとしています。Day トレで学習した最初の子ども達は既に40歳代。立派な社会人または良きお父さんやお母さんになられていることでしょう。そして、この名前（当時は「デイトレ」の愛称）を見て懐かしく思い出されるかもしれません。当時、安藤T氏（人生のパートナー）が担当していた６年生の中学受験クラスに計算練習と一行問題が毎日できるようにとB4プリントを綴じる形態で、正確かつスピードのある計算力と各単元の基礎・基本事項の定着を目的にスタートさせたのが「デイトレ」の始まりです。下記は安藤T氏のコメントです。
                        </li>
                        <li className="text-md mb-6">
                            ところが一年間コンスタントに取り組める子どもは、ほんの一握りですので、指導には相当の忍耐力とエネルギーが必要でした。悪戦苦闘しながらも何とか一年間継続させ子ども達は中学受験に臨みました。そして、合格発表で信じられないことが起きました。　クラスのほぼ全員が俗に言うチャレンジ校に合格していたのです。そこで私は強く確信いたしました「デイトレ」に優るものはない！と・・・
                        </li>
                        <li className="text-md mb-6">
                            こうして平成元年に４年生から６年生の中学受験「デイトレ」が正式に誕生、改良を積み重ね今日に至っております。「デイトレ」で鍛えられる子ども達を見ていて、毎日の積み重ねがいかに大切か、その結果として確実に表れる子ども達の成果。「デイトレの威力」を私も革新し脳の柔らかい時期こそ、この学習習慣の定着と自ら考える力が絶対に必要だと「読む力」「書く力」「考える力」を鍛える「読考書」をコンセプトに低学年を対象とした教材開発に着手しました。そして、考察したひとつが「知育Dayトレ」です。ⅠDayトレからⅢDayトレの最後の問題は「発想力・着眼力」などを楽しく身につけられるように教科に拘らない能力問題を取り入れました。この問題が親子会話のツールのひとつに加わって下さると幸いです。自信を深め意欲的に学習に取り組むようになった子ども達の姿を見るのは素敵で嬉しいことです。このDayトレが「真に生きる力」の土台作りになってくれることを願っております。
                        </li>
                    </ul>
                </div>
            </Layout>
        </>
    )
}