import reactStringReplace from "react-string-replace"
import Header from './elements/Header'
import Layout from './elements/Layout'
import { H4 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import { CheckboxMultiple } from './elements/Form'
import { Textfield } from './elements/Form'
import { Textarea } from './elements/Form'
import { ZipcodeForm } from './elements/Form'
import { Checkbox } from './elements/Form'
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { init, send } from 'emailjs-com';

import axios from "axios";
import { ChangeEvent, useState } from "react";

import Banner1 from '../img/contact2/banner1.png'

type Zipcode = {
    main: string;
    sub: string;
}
type Address = {
    address1: string;
    address2: string;
}

const contactItems = [
    '高校受験情報サイト 広告掲載',
    'メーチラ',
    'One Read（スクール・塾管理システム）',
    'プログラ道場',
    'プログラ部活',
    'SAKURASAKU NOTE',
    'チアスタディ（オンライン学習システム）',
    'その他',
]

function encode(key: any, val: any) {
    if (key == 'contacts') {
        if (val == 1) {
            return '高校受験情報サイト 広告掲載'
        } else if (val == 2) {
            return 'メーチラ'
        } else if (val == 3) {
            return 'One Read（スクール・塾管理システム）'
        } else if (val == 4) {
            return 'プログラ道場'
        } else if (val == 5) {
            return 'プログラ部活'
        } else if (val == 6) {
            return 'SAKURASAKU NOTE'
        } else if (val == 7) {
            return 'チアスタディ（オンライン学習システム）'
        } else if (val == 8) {
            return 'その他'
        } else {
            return val
        }
    } else {
        return val
    }
}
function sendJS(data: any) {
    if (data.agreement == false || data.agreement.length == 0) {
        alert('個人情報の取扱いについてへ同意してください。')
    } else if (data.contacts == false || data.agreement.contacts == 0) {
        alert('お問い合わせを選択してください。')
    } else if (data.hojin_mei == '') {
        alert('法人・団体名を入力してください。')
    } else if (data.tanto_mei == '') {
        alert('氏名・担当者を選択してください。')
    } else if (data.email == '') {
        alert('メールアドレスを選択してください。')
    } else if (data.phone == '') {
        alert('連絡先を選択してください。')
    } else if (data.zipcode_main == '' || data.zipcode_sub == '') {
        alert('郵便番号を選択してください。')
    } else if (data.address1 == '' || data.address2 == '' || data.address3 == '') {
        alert('住所を選択してください。')
    } else {
        // contacts
        for (let i = 0; i < data.contacts.length; i++) data.contacts[i] = encode('contacts', data.contacts[i])
        // questions
        if (data.questions == undefined || data.questions == '') data.questions = 'ご質問等はありません。'
        // EmailJS
        const publicKey = process.env.REACT_APP_PUBLIC_KEY
        const serviceID = process.env.REACT_APP_SERVICE_ID
        const templateID = process.env.REACT_APP_TEMPLATE_ID_CONTACT
        if (publicKey !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(publicKey);
            send(serviceID, templateID, data).then((result) => {
                alert('お問い合わせありがとうございました。ご入力いただいたメールアドレスに確認メールをお送りしました。確認メールが届かない場合はお手数ですが、info@terra-cco.jp までご連絡ください。')
            }, (error) => {
                alert('お問い合わせの送信に失敗しました。お手数ですが、info@terra-cco.jp までご連絡ください。')
            });
        }
    }
}

export default function Contact2() {

    const regExp = /(https?:\/\/\S+)/g

    const [zipcode, setZipcodeMain] = useState<Zipcode>({
        main: "",
        sub: ""
    });
    const [address, setAddress] = useState<Address>({
        address1: "",
        address2: ""
    });

    const updateZipcodeAddress1 = (e: ChangeEvent<HTMLInputElement>) => {
        setAddress({
            ...address,
            address1: e.target.value
        });
    };
    const updateZipcodeAddress2 = (e: ChangeEvent<HTMLInputElement>) => {
        setAddress({
            ...address,
            address2: e.target.value
        });
    };

    const updateZipcodeMain = (e: ChangeEvent<HTMLInputElement>) => {
        setZipcodeMain({
            ...zipcode, main: e.target.value
        });
    };
    const updateZipcodeSub = async (e: ChangeEvent<HTMLInputElement>) => {
        setZipcodeMain({
            ...zipcode,
            sub: e.target.value
        });
        if (e.target.value.length === 4 && zipcode.main.length === 3) {
            try {
                const res = await axios.get(
                    "https://zipcloud.ibsnet.co.jp/api/search",
                    {
                        params: {
                            zipcode: zipcode.main + e.target.value
                        }
                    }
                )
                if (res.data.results) {
                    const result = res.data.results[0];
                    setAddress({
                        ...address,
                        address1: result["address1"],
                        address2: result["address2"] + result["address3"]
                    })
                    setValue("address1", result["address1"])
                    setValue("address2", result["address2"] + result["address3"])
                }
            } catch {
                alert("住所の取得に失敗しました。")
            }
        }
    }

    const { register, handleSubmit, setValue } = useForm()

    const contact = async (data: any) => {
        sendJS(data)
    }

    const location = useLocation();


    return (
        <>
            <Header />
            <Layout>
                <H4>お問い合わせ</H4>
                <form onSubmit={handleSubmit(contact)} className="max-w-xl m-auto bg-white bg-opacity-25 rounded border divide-y">

                    <div className="text-center font-bold bg-gray-400 text-white p-2">
                        Terra-Cco（テラッコ）お問い合わせ
                    </div>

                    <img src={Banner1} className="w-full object-cover object-center p-2" alt="" />

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="">
                            <div className="font-bold">
                                てらっこクラブ
                            </div>
                            <div className="pb-2">
                                {reactStringReplace('https://www.terracco-club.jp/', regExp, (match, i) => (
                                    <a href={match} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                        ▶ https://www.terracco-club.jp/
                                    </a>
                                ))}
                                にて直接販売してます。
                            </div>
                            <div className="text-red-500 font-bold">
                                防災商品（団体向け）のご注文はこちら
                            </div>
                            <div>
                                {reactStringReplace('https://ws.formzu.net/fgen/S80135895/', regExp, (match, i) => (
                                    <a href={match} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                        ▶ https://ws.formzu.net/fgen/S80135895/
                                    </a>
                                ))}
                                にて直接販売してます。
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/3">
                            <ul>
                                <li className="m-3 text-sm">
                                    お問い合わせ（複数選択可）
                                    <span className="text-red-500 ml-2">必須</span>
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                            <CheckboxMultiple register={register('contacts')} items={contactItems} />
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/3">
                            <ul>
                                <li className="m-3 text-sm">
                                    法人・団体名
                                    <span className="text-red-500 ml-2">必須</span>
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                            <Textfield register={register('hojin_mei')} placeholder={null} disabled={false} onChange={null} />
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/3">
                            <ul>
                                <li className="m-3 text-sm">
                                    氏名・担当者
                                    <span className="text-red-500">必須</span>
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                            <Textfield register={register('tanto_mei')} placeholder={null} disabled={false} onChange={null} />
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/3">
                            <ul>
                                <li className="m-3 text-sm">
                                    メールアドレス
                                    <span className="text-red-500 ml-2">必須</span>
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                            <Textfield register={register('email')} placeholder={null} disabled={false} onChange={null} />
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/3">
                            <ul>
                                <li className="m-3 text-sm">
                                    TEL番号
                                    <span className="text-red-500 ml-2">必須</span>
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                            <Textfield register={register('phone')} placeholder={null} disabled={false} onChange={null} />
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/3">
                            <ul>
                                <li className="m-3 text-sm">
                                    住所
                                    <span className="text-red-500 ml-2">必須</span>
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">

                            <div className="flex flex-col sm:flex-row p-2">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                                    郵便番号
                                </div>
                                <div className="sm:w-4/5">
                                    <div className="grid grid-cols-1 gap-4 text-center grid-cols-2">
                                        <div>
                                            <ZipcodeForm register={register('zipcode_main')} value={zipcode.main} placeholder={''} onChange={updateZipcodeMain} />
                                        </div>
                                        <div>
                                            <ZipcodeForm register={register('zipcode_sub')} value={zipcode.sub} placeholder={null} onChange={updateZipcodeSub} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row p-2">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                                    都道府県
                                </div>
                                <div className="sm:w-4/5">
                                    <ZipcodeForm register={register('address1')} value={address.address1} placeholder={''} onChange={updateZipcodeAddress1} />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row p-2">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                                    市区町村
                                </div>
                                <div className="sm:w-4/5">
                                    <ZipcodeForm register={register('address2')} value={address.address2} placeholder={''} onChange={updateZipcodeAddress2} />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row p-2">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                                    町名番地等
                                </div>
                                <div className="sm:w-4/5">
                                    <Textfield register={register('address3')} placeholder={''} disabled={false} onChange={null} />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row p-2">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                                    建物名
                                </div>
                                <div className="sm:w-4/5">
                                    <Textfield register={register('building')} placeholder={''} disabled={false} onChange={null} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/3">
                            <ul>
                                <li className="m-3 text-sm">
                                    ご質問等
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                            <Textarea register={register('questions')} />
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="text-sm font-bold text-gray-500">
                            送信するには個人情報の取扱いについてへの同意が必要です。
                            <span className="text-red-500 text-sm">必須</span>
                            <Checkbox register={register('agreement')} />
                        </div>
                    </div>

                    <button type="submit" className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2" >
                        送信
                    </button>

                </form>
            </Layout>
        </>
    )
}