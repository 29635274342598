import reactStringReplace from "react-string-replace"
import Header from './elements/Header'
import Layout from './elements/Layout'
import { H4 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import { CheckboxMultiple } from './elements/Form'
import { Textfield } from './elements/Form'
import { Textarea } from './elements/Form'
import { ZipcodeForm } from './elements/Form'
import { Checkbox } from './elements/Form'
import { useForm } from "react-hook-form";
import { init, send } from 'emailjs-com';

import axios from "axios";
import { ChangeEvent, useState } from "react";

import Banner1 from '../img/contact2/banner1.png'
import { count } from 'console'

type Zipcode = {
    main: string;
    sub: string;
}
type Address = {
    address1: string;
    address2: string;
}

function sendJS(data: any) {
    if (data.agreement == false || data.agreement.length == 0) {
        alert('個人情報の取扱いについてへ同意してください。')
    } else if (data.total == undefined || data.total == 0) {
        alert('ご注文の商品を選択してください。')
    } else if (data.hojin_mei == '') {
        alert('法人・団体名を入力してください。')
    } else if (data.tanto_mei == '') {
        alert('氏名・担当者を選択してください。')
    } else if (data.email == '') {
        alert('メールアドレスを選択してください。')
    } else if (data.phone == '') {
        alert('TEL番号を選択してください。')
    } else if (data.zipcode_main == '' || data.zipcode_sub == '') {
        alert('郵便番号を選択してください。')
    } else if (data.address1 == '' || data.address2 == '' || data.address3 == '') {
        alert('住所を選択してください。')
    } else {
        // domain & router & purpose
        data.domain = 'Terra-Cco（テラッコ）'
        data.router = '個人向けご注文ページ'
        data.purpose = '商品のご注文'
        // hojin_mei
        if (data.hojin_mei == undefined || data.hojin_mei == '') data.hojin_mei = '-'
        // questions
        if (data.questions == undefined || data.questions == '') data.questions = 'ご質問等はありません。'
        // EmailJS
        const publicKey = process.env.REACT_APP_PUBLIC_KEY
        const serviceID = process.env.REACT_APP_SERVICE_ID
        const templateID = process.env.REACT_APP_TEMPLATE_ID_ORDER
        if (publicKey !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(publicKey)
            send(serviceID, templateID, data).
                then((result) => {
                    alert('お問い合わせありがとうございました。ご入力いただいたメールアドレスに確認メールをお送りしました。確認メールが届かない場合はお手数ですが、example@sample.com までご連絡ください。')
                }, (error) => {
                    alert('ご注文の送信に失敗しました。お手数ですが、info@terra-cco.jp までご連絡ください。')
                });
        }
    }
}

export default function ContactKojin() {

    const regExp = /(https?:\/\/\S+)/g

    const { register, handleSubmit, setValue, getValues } = useForm()

    const [zipcode, setZipcodeMain] = useState<Zipcode>({
        main: "",
        sub: ""
    });
    const [address, setAddress] = useState<Address>({
        address1: "",
        address2: ""
    });

    const updateZipcodeAddress1 = (e: ChangeEvent<HTMLInputElement>) => {
        setAddress({
            ...address,
            address1: e.target.value
        });
    };
    const updateZipcodeAddress2 = (e: ChangeEvent<HTMLInputElement>) => {
        setAddress({
            ...address,
            address2: e.target.value
        });
    };
    const updateZipcodeMain = (e: ChangeEvent<HTMLInputElement>) => {
        setZipcodeMain({
            ...zipcode,
            main: e.target.value
        });
    };
    const updateZipcodeSub = async (e: ChangeEvent<HTMLInputElement>) => {
        setZipcodeMain({
            ...zipcode,
            sub: e.target.value
        });
        if (e.target.value.length === 4 && zipcode.main.length === 3) {
            try {
                const res = await axios.get(
                    "https://zipcloud.ibsnet.co.jp/api/search",
                    {
                        params: {
                            zipcode: zipcode.main + e.target.value
                        }
                    }
                )
                if (res.data.results) {
                    const result = res.data.results[0];
                    setAddress({
                        ...address,
                        address1: result["address1"],
                        address2: result["address2"] + result["address3"]
                    })
                    setValue("address1", result["address1"])
                    setValue("address2", result["address2"] + result["address3"])
                }
            } catch {
                alert("住所の取得に失敗しました。")
            }
        }
    }

    const contact = async (data: any) => {
        sendJS(data)
    }

    const handleChange = (event: any) => {
        // console.dir(event)
    }

    const handleCalculation = () => {

        let total = 0
        const values = getValues()
        for (let key in values) {
            if (String(key).indexOf('_yen') !== -1) {
                total = total + (!isNaN(Number(values[key])) ? Number(values[key]) : 0);
            }
        }

        let postage = 0
        if (total != 0 && total >= 20000) {
            postage = 0
        } else if (total != 0 && total < 20000 && 5001 <= total) {
            postage = 1870
        } else if (total != 0 && total <= 5000) {
            postage = 880
        }

        return {
            postage: postage,
            total: total + postage
        }
    }

    const handlePostage = () => {
        return 100
    }

    return (
        <>
            <Header />
            <Layout>
                <H4>個人ご注文</H4>
                <form onSubmit={handleSubmit(contact)} className="max-w-xl m-auto bg-white bg-opacity-25 rounded border divide-y">

                    <div className="text-center font-bold bg-gray-400 text-white p-2">
                        Terra-Cco（テラッコ）個人ご注文
                    </div>

                    <img src={Banner1} className="w-full object-cover object-center p-2" alt="" />

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="">
                            <div className="font-bold">
                                てらっこクラブ
                            </div>
                            <div className="pb-2">
                                {reactStringReplace('https://www.terracco-club.jp/', regExp, (match, i) => (
                                    <a href={match} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                        ▶ https://www.terracco-club.jp/
                                    </a>
                                ))}
                                にて直接販売してます。
                            </div>
                            <div className="text-red-500 font-bold">
                                防災商品（団体向け）のご注文はこちら
                            </div>
                            <div>
                                {reactStringReplace('https://ws.formzu.net/fgen/S80135895/', regExp, (match, i) => (
                                    <a href={match} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                        ▶ https://ws.formzu.net/fgen/S80135895/
                                    </a>
                                ))}
                                にて直接販売してます。
                            </div>
                        </div>
                    </div>

                    {[
                        { name: '氏名', register: register('tanto_mei'), disabled: false },
                        { name: 'メールアドレス', register: register('email'), disabled: false },
                        { name: 'TEL番号', register: register('phone'), disabled: false },
                    ].map((item: any, key: any) => (
                        <div key={key} className="flex flex-col sm:flex-row">
                            <div className="sm:w-1/3 bg-gray-300 p-2">
                                <ul>
                                    <li className="m-3 text-sm">
                                        {item.name}
                                        <span className="text-red-500 ml-2">必須</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="sm:w-2/3 p-2">
                                <Textfield register={item.register} placeholder={null} disabled={item.disabled} onChange={handleChange} />
                            </div>
                        </div>
                    ))}

                    <div className="flex flex-col sm:flex-row">
                        <div className="sm:w-1/3 bg-gray-300 p-2">
                            <ul>
                                <li className="m-3 text-sm">
                                    住所
                                    <span className="text-red-500 ml-2">必須</span>
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-2/3 p-2">

                            <div className="flex flex-col sm:flex-row p-1">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-1">
                                    郵便番号
                                </div>
                                <div className="sm:w-4/5">
                                    <div className="grid grid-cols-1 text-center grid-cols-2">
                                        <div className="pr-1">
                                            <ZipcodeForm register={register('zipcode_main')} value={zipcode.main} placeholder={''} onChange={updateZipcodeMain} />
                                        </div>
                                        <div className="pl-1">
                                            <ZipcodeForm register={register('zipcode_sub')} value={zipcode.sub} placeholder={null} onChange={updateZipcodeSub} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row p-1">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-1">
                                    都道府県
                                </div>
                                <div className="sm:w-4/5">
                                    <ZipcodeForm register={register('address1')} value={address.address1} placeholder={''} onChange={updateZipcodeAddress1} />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row p-1">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-1">
                                    市区町村
                                </div>
                                <div className="sm:w-4/5">
                                    <ZipcodeForm register={register('address2')} value={address.address2} placeholder={''} onChange={updateZipcodeAddress2} />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row p-1">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-1">
                                    町名番地等
                                </div>
                                <div className="sm:w-4/5">
                                    <Textfield register={register('address3')} placeholder={''} disabled={false} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row p-1">
                                <div className="sm:w-1/5 text-xs mt-4 text-left pb-1">
                                    建物名
                                </div>
                                <div className="sm:w-4/5">
                                    <Textfield register={register('building')} placeholder={''} disabled={false} onChange={handleChange} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/1">
                            <ul>
                                <li className="m-3 text-sm">
                                    <b>ご注文の際は注文数をご入力下さい。金額は全て税込みとなります。</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {[
                        {
                            name: 'レキシとコトバの先生（上）',
                            price: '1,430円',
                            copy: register('rekikoto_1_copy'),
                            yen: register('rekikoto_1_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1430
                                setValue("rekikoto_1_copy", copy)
                                setValue("rekikoto_1_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'レキシとコトバの先生（下）',
                            price: '1,430円',
                            copy: register('rekikoto_2_copy'),
                            yen: register('rekikoto_2_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1430
                                setValue("rekikoto_2_copy", copy)
                                setValue("rekikoto_2_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'セカイシとコトバの先生',
                            price: '1,430円',
                            copy: register('sekakoto_copy'),
                            yen: register('sekakoto_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1430
                                setValue("sekakoto_copy", copy)
                                setValue("sekakoto_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'レキコトEnglish Part1',
                            price: '1,430円',
                            copy: register('rekikoto_english_1_copy'),
                            yen: register('rekikoto_english_1_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1430
                                setValue("rekikoto_english_1_copy", copy)
                                setValue("rekikoto_english_1_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'レキコトEnglish Part2',
                            price: '1,430円',
                            copy: register('rekikoto_english_2_copy'),
                            yen: register('rekikoto_english_2_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1430
                                setValue("rekikoto_english_2_copy", copy)
                                setValue("rekikoto_english_2_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'レキコト×英語 読み分けシート',
                            price: '330円',
                            copy: register('rekikoto_english_sheet_copy'),
                            yen: register('rekikoto_english_sheet_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 330
                                setValue("rekikoto_english_sheet_copy", copy)
                                setValue("rekikoto_english_sheet_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'ビジョンモンスター',
                            price: '1,210円',
                            copy: register('vision_monster_copy'),
                            yen: register('vision_monster_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1210
                                setValue("vision_monster_copy", copy)
                                setValue("vision_monster_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'レキシとコトバの先生(上)ＣＤ',
                            price: '1,650円',
                            copy: register('rekikoto_1_cd_copy'),
                            yen: register('rekikoto_1_cd_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("rekikoto_1_cd_copy", copy)
                                setValue("rekikoto_1_cd_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'レキシとコトバの先生(下)ＣＤ',
                            price: '1,650円',
                            copy: register('rekikoto_2_cd_copy'),
                            yen: register('rekikoto_2_cd_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("rekikoto_2_cd_copy", copy)
                                setValue("rekikoto_2_cd_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'レキコトEnglish Part.1 ＣＤ',
                            price: '1,650円',
                            copy: register('rekikoto_english_1_cd_copy'),
                            yen: register('rekikoto_english_1_cd_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("rekikoto_english_1_cd_copy", copy)
                                setValue("rekikoto_english_1_cd_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'レキコトEnglish Part.2 ＣＤ',
                            price: '1,650円',
                            copy: register('rekikoto_english_2_cd_copy'),
                            yen: register('rekikoto_english_2_cd_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("rekikoto_english_2_cd_copy", copy)
                                setValue("rekikoto_english_2_cd_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'との先生クリアファイル（紫)',
                            price: '165円',
                            copy: register('tono_clear_purple_copy'),
                            yen: register('tono_clear_purple_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 165
                                setValue("tono_clear_purple_copy", copy)
                                setValue("tono_clear_purple_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'との先生クリアファイル（黄色）',
                            price: '165円',
                            copy: register('tono_clear_yellow_copy'),
                            yen: register('tono_clear_yellow_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 165
                                setValue("tono_clear_yellow_copy", copy)
                                setValue("tono_clear_yellow_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'との先生クリアファイル（1ｾｯﾄ20枚）',
                            price: '2,970円',
                            copy: register('tono_clear_files_copy'),
                            yen: register('tono_clear_files_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 2970
                                setValue("tono_clear_files_copy", copy)
                                setValue("tono_clear_files_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'プログラ道場チャレンジワールド',
                            price: '1650円',
                            copy: register('progra_challenge_world_copy'),
                            yen: register('progra_challenge_world_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("progra_challenge_world_copy", copy)
                                setValue("progra_challenge_world_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'プログラ道場パズルワールド',
                            price: '1980円',
                            copy: register('progra_puzzle_world_copy'),
                            yen: register('progra_puzzle_world_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1980
                                setValue("progra_puzzle_world_copy", copy)
                                setValue("progra_puzzle_world_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'SDGsマイスター検定問題集（学生用）',
                            price: '3,850円',
                            copy: register('sdgs_gakusei_student_copy'),
                            yen: register('sdgs_gakusei_student_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 3850
                                setValue("sdgs_gakusei_student_copy", copy)
                                setValue("sdgs_gakusei_student_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'SDGsマイスター検定問題集（社会人用）',
                            price: '3,850円',
                            copy: register('sdgs_gakusei_worker_copy'),
                            yen: register('sdgs_gakusei_worker_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 3850
                                setValue("sdgs_gakusei_worker_copy", copy)
                                setValue("sdgs_gakusei_worker_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'SDGsから学ぶ知識と探究のワーク',
                            price: '1,650円',
                            copy: register('sdgs_works_copy'),
                            yen: register('sdgs_works_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("sdgs_works_copy", copy)
                                setValue("sdgs_works_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: '１ Dayトレ Part1',
                            price: '2,750円',
                            copy: register('one_day_train_1_copy'),
                            yen: register('one_day_train_1_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 2750
                                setValue("one_day_train_1_copy", copy)
                                setValue("one_day_train_1_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: '１ Dayトレ Part2',
                            price: '2,200円',
                            copy: register('one_day_train_2_copy'),
                            yen: register('one_day_train_2_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 2200
                                setValue("one_day_train_2_copy", copy)
                                setValue("one_day_train_2_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: '１ Dayトレ Part3',
                            price: '2,200円',
                            copy: register('one_day_train_3_copy'),
                            yen: register('one_day_train_3_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 2200
                                setValue("one_day_train_3_copy", copy)
                                setValue("one_day_train_3_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: '２ Dayトレ Part1',
                            price: '2,200円',
                            copy: register('two_day_train_1_copy'),
                            yen: register('two_day_train_1_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 2200
                                setValue("two_day_train_1_copy", copy)
                                setValue("two_day_train_1_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: '２ Dayトレ Part2',
                            price: '2,200円',
                            copy: register('two_day_train_2_copy'),
                            yen: register('two_day_train_2_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 2200
                                setValue("two_day_train_2_copy", copy)
                                setValue("two_day_train_2_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: '２ Dayトレ Part3',
                            price: '2,200円',
                            copy: register('two_day_train_3_copy'),
                            yen: register('two_day_train_3_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 2200
                                setValue("two_day_train_3_copy", copy)
                                setValue("two_day_train_3_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'FUTURE（小学生・中学受験希望者）',
                            price: '1,650円',
                            copy: register('future_0_copy'),
                            yen: register('future_0_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("future_0_copy", copy)
                                setValue("future_0_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'FUTURE（中学、高校生 Vol.1）',
                            price: '1,650円',
                            copy: register('future_1_copy'),
                            yen: register('future_1_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("future_1_copy", copy)
                                setValue("future_1_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'FUTURE（中学、高校生 Vol.2）',
                            price: '1,650円',
                            copy: register('future_2_copy'),
                            yen: register('future_2_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("future_2_copy", copy)
                                setValue("future_2_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'FUTURE（Vol.3 STEAM）',
                            price: '1,650円',
                            copy: register('future_3_copy'),
                            yen: register('future_3_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 1650
                                setValue("future_3_copy", copy)
                                setValue("future_3_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: '受験スケジュール帳（アナログママ）',
                            price: '2,750円',
                            copy: register('schedule_book_copy'),
                            yen: register('schedule_book_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 2750
                                setValue("schedule_book_copy", copy)
                                setValue("schedule_book_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        },
                        {
                            name: 'クリアシートマスク（1ｾｯﾄ10枚）',
                            price: '330円',
                            copy: register('clear_sheets_copy'),
                            yen: register('clear_sheets_yen'),
                            handleChange: (e: any) => {
                                const copy = e.target.value
                                const yen = e.target.value * 330
                                setValue("clear_sheets_copy", copy)
                                setValue("clear_sheets_yen", yen)
                                const result = handleCalculation()
                                setValue("total", result.total)
                                setValue("postage", result.postage)
                            }
                        }
                    ].map((product: any, key: any) => (
                        <div key={key} className="flex flex-col sm:flex-row p-2">
                            <div className="sm:w-3/12 m-4 text-sm font-bold">
                                {product.name}
                            </div>
                            <div className="sm:w-2/12 m-4 text-sm">
                                {product.price}
                            </div>
                            <div className="sm:w-7/12 grid grid-cols-1 text-center grid-cols-4">
                                <div className="pt-1">
                                    <Textfield register={product.copy} placeholder={null} disabled={false} onChange={product.handleChange} />
                                </div>
                                <div className="pt-3">
                                    部
                                </div>
                                <div className="pt-1">
                                    <Textfield register={product.yen} placeholder={0} disabled={true} onChange={product.handleChange} />
                                </div>
                                <div className="pt-3">
                                    円
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-5/12 m-4 text-sm font-bold">
                            【送料】
                        </div>
                        <div className="sm:w-7/12 grid grid-cols-1 text-center grid-cols-4">
                            <div className="pt-1">

                            </div>
                            <div className="pt-3">

                            </div>
                            <div className="pt-1">
                                <Textfield register={register('postage')} placeholder={0} disabled={true} onChange={handleChange} />
                            </div>
                            <div className="pt-3">
                                円
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-5/12 m-4 text-sm font-bold">
                            【合計】
                        </div>
                        <div className="sm:w-7/12 grid grid-cols-1 text-center grid-cols-4">
                            <div className="pt-1">

                            </div>
                            <div className="pt-3">

                            </div>
                            <div className="pt-1">
                                <Textfield register={register('total')} placeholder={0} disabled={true} onChange={handleChange} />
                            </div>
                            <div className="pt-3">
                                円
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/1">
                            <ul>
                                <li className="m-3 text-sm">
                                    <b>送料</b>
                                </li>
                                <li className="m-3 text-sm">
                                    <b>合計 ￥20,000以上 無料</b>
                                </li>
                                <li className="m-3 text-sm">
                                    <b>合計 ￥ 5,001以上 送料 ￥1,870</b>
                                </li>
                                <li className="m-3 text-sm">
                                    <b>合計 ￥ 5,000以下 送料 ￥ 880</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row">
                        <div className="sm:w-1/3 bg-gray-300 p-2">
                            <ul>
                                <li className="m-3 text-sm">
                                    ご質問等
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-2/3 p-2">
                            <Textarea register={register('questions')} />
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row p-2">
                        <div className="text-sm font-bold text-gray-500">
                            送信するには個人情報の取扱いについてへの同意が必要です。
                            <span className="text-red-500 text-sm">必須</span>
                            <Checkbox register={register('agreement')} />
                        </div>
                    </div>

                    <button type="submit" className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2" >
                        送信
                    </button>

                </form>
            </Layout>
        </>
    )
}