import { useNavigate } from "react-router-dom";
import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'

import Img1 from '../img/sakurasakunote/img1.png'
import Img2 from '../img/sakurasakunote/img2.png'
import Img3 from '../img/sakurasakunote/img3.jpeg'
import Img4 from '../img/sakurasakunote/img4.gif'
import Img5 from '../img/sakurasakunote/img5.gif'
import Img6 from '../img/sakurasakunote/img6.png'
import Img7 from '../img/sakurasakunote/img7.png'
import Img8 from '../img/sakurasakunote/img8.png'
import Img9 from '../img/sakurasakunote/img9.png'
import Img10 from '../img/sakurasakunote/img10.png'
import Img11 from '../img/sakurasakunote/img11.png'

import Banner1 from '../img/sakurasakunote/banner1.jpeg'

export default function SakurasakuNote() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <Layout>
                <H4>サクラサクノート（学校・学習塾向け オリジナル手帳）</H4>
                <div className="grid gap-2 sm:grid-cols-1">
                    <Image1 img={Img1} />
                    <Image2 imgs={[{ img: Img2 }, { img: Img3 }]} />
                    <Image2 imgs={[{ img: Img4 }, { img: Img5 }]} />
                    <Image2 imgs={[{ img: Img6 }, { img: Img7 }]} />
                    <Image2 imgs={[{ img: Img8 }, { img: Img9 }]} />
                    <Image2 imgs={[{ img: Img10 }, { img: Img11 }]} />
                </div>
                <div className="mb-3">
                    <a href="/pdf/sakurasaku_note.pdf" download >
                        <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg">SAKURASAKU NOTE チラシ ダウンロード</button>
                    </a>
                </div>
                <div className="grid gap-2 lg:grid-cols-2">
                    {[
                        {
                            img: Banner1,
                            url: `./contact`
                        },
                    ].map((item, key) => (
                        <div key={key} className="cursor" onClick={() => navigate(item.url)}>
                            <Image1 img={item.img} />
                        </div>
                    ))}
                </div>
            </Layout>
        </>
    )
}