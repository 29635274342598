import reactStringReplace from "react-string-replace"
import Header from './elements/Header'
import Layout from './elements/Layout'
import { Slider2WithContent } from './elements/Slider'
import { Image2WithContent, Image1 } from './elements/Image'
import { H2, H3, H4 } from './elements/Heading'

import Img1 from '../img/miyapickles/img1.jpeg'
import Img2 from '../img/miyapickles/img2.jpeg'
import Img3 from '../img/miyapickles/img3.jpeg'
import Img4 from '../img/miyapickles/img4.jpeg'
import Img5 from '../img/miyapickles/img5.jpeg'

import Swiper1 from '../img/miyapickles/swiper1.jpeg'
import Swiper2 from '../img/miyapickles/swiper2.jpeg'
import Swiper3 from '../img/miyapickles/swiper3.jpeg'
import Swiper4 from '../img/miyapickles/swiper4.jpeg'

import Banner1 from '../img/miyapickles/banner1.png'

export default function Miyapickles() {
    const regExp = /(https?:\/\/\S+)/g
    return (
        <>
            <Header />
            <Layout>
                <H4>宮ぴくるす・ふるーつぴくるす</H4>

                <Image1 img={Img1} />

                <Slider2WithContent arr={[
                    {
                        title:
                            '宮ぴくるす ６種ギフトセット',
                        subtitle:
                            '価格：￥4,500（税込：￥4,950）',
                        description:
                            '・かんぴょうと根菜のピクルス' + "\n" +
                            '・いろいろ野菜のピクルス' + "\n" +
                            '・ぷちとまとのピクルス' + "\n" +
                            '・うずらの卵のピクルス' + "\n" +
                            '・しいたけのピクルス' + "\n" +
                            '・大豆のピクルス' + "\n\n" +
                            '保存料不使用、ギフトボックス（専用箱）に詰め合わせ、宇都宮で製造されたもろみ酢を使用',
                        slide: [
                            Swiper1,
                            Swiper2,
                        ],
                    },
                    {
                        title:
                            '宮ぴくるす ３種ギフトセット',
                        subtitle:
                            '価格：￥2,500（税込：￥2,750）',
                        description:
                            '・かんぴょうと根菜のピクルス' + "\n" +
                            '・いろいろ野菜のピクルス' + "\n" +
                            '・ぷちとまとのピクルス' + "\n" +
                            '・うずらの卵のピクルス' + "\n" +
                            '・しいたけのピクルス' + "\n" +
                            '・大豆のピクルス' + "\n\n" +
                            '■ 上記６種より３品お選び下さい（同一種類・複数本数可）' + "\n\n" +
                            '保存料不使用、ギフトボックス（専用箱）に詰め合わせ、宇都宮で製造されたもろみ酢を使用',
                        slide: [
                            Swiper3,
                            Swiper4,
                        ],
                    },
                ]} />

                <div className="pb-4">
                    <div className="flex justify-center">
                        {reactStringReplace('https://ws.formzu.net/fgen/S88786752/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <img src={Banner1} className="w-96" alt="" />
                            </a>
                        ))}
                    </div>
                </div>

                <Image2WithContent arr={[
                    {
                        title:
                            'ふるーつぴくるす ６種ギフトセット',
                        subtitle:
                            '・価格：￥4,300（税込：￥4,730）',
                        description:
                            '■ 果実の美味しさ、素《酢》のまま' + "\n" +
                            '「宮ぴくるすふるーつ」は宇都宮で採れた果物を、水を使用せず、採れたてのまま、240年続く酢蔵のお酢と上質なグラニュー糖のみで作っています。' + "\n" +
                            '果実はもちろんのこと、ピクルス液だけでも風味や香りが素のまま楽しめます。' + "\n\n" +
                            '・いちご　・宮れもん　・梨' + "\n" +
                            '・キウイ　・ブルーベリー　・いちじく' + "\n\n" +
                            'ギフトボックス（専用箱）に詰め合わせ' + "\n" +
                            '宇都宮で製造された、もろみ酢を使用。',
                        img: Img2,
                    },
                    {
                        title:
                            'ふるーつぴくるす ３種セット',
                        subtitle:
                            '・価格：￥2,210（税込：￥2,430）',
                        description:
                            '■ 果実の美味しさ、素《酢》のまま' + "\n" +
                            '「宮ぴくるすふるーつ」は宇都宮で採れた果物を、水を使用せず、採れたてのまま、240年続く酢蔵のお酢と上質なグラニュー糖のみで作っています。' + "\n" +
                            '果実はもちろんのこと、ピクルス液だけでも風味や香りが素のまま楽しめます。' + "\n\n" +
                            '・いちご　・宮れもん　・梨' + "\n" +
                            '・キウイ　・ブルーベリー　・いちじく' + "\n\n" +
                            '■ 上記6種より３品お選び下さい（同一種類・複数本数可）' + "\n" +
                            '宇都宮で製造された、もろみ酢を使用。',
                        img: Img3,
                    },
                ]} />

                <div className="pb-4">
                    <div className="flex justify-center">
                        {reactStringReplace('https://ws.formzu.net/fgen/S88786752/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <img src={Banner1} className="w-96" alt="" />
                            </a>
                        ))}
                    </div>
                </div>

                <Image2WithContent arr={[
                    {
                        title:
                            '宮ぴくるす 単品',
                        subtitle:
                            '・価格：￥880（税込：￥968）',
                        description:
                            '果実はもちろんのこと、ピクルス液だけでも風味や香りが素のまま楽しめます。' + "\n\n" +
                            '・いちご　・宮れもん　・梨' + "\n" +
                            '・キウイ　・ブルーベリー　・いちじく' + "\n\n" +
                            '宇都宮で製造された、もろみ酢を使用。',
                        img: Img4,
                    },
                    {
                        title:
                            'ふるーつぴくるす 単品',
                        subtitle:
                            '・価格：￥880（税込：￥968）',
                        description:
                            '・いちご　・宮れもん　・梨' + "\n" +
                            '・キウイ　・ブルーベリー　・いちじく' + "\n\n" +
                            '■ 上記6種より３品お選び下さい（同一種類・複数本数可）' + "\n" +
                            '宇都宮で製造された、もろみ酢を使用。',
                        img: Img5,
                    },
                ]} />

                <div className="pb-4">
                    <div className="flex justify-center">
                        {reactStringReplace('https://ws.formzu.net/fgen/S88786752/', regExp, (match, i) => (
                            <a href={match} target="_blank" rel="noopener noreferrer">
                                <img src={Banner1} className="w-96" alt="" />
                            </a>
                        ))}
                    </div>
                </div>

            </Layout>
        </>
    )
}