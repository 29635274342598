import { useNavigate } from "react-router-dom"
import Header from './elements/Header'
import Layout from './elements/Layout'
import { H1, H2, H3, H4, H5, H6 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'
import Banner1 from '../img/future/banner1.jpeg'
import Img1 from '../img/future/img1.jpeg'
import Img2 from '../img/future/img2.jpeg'
import Img3 from '../img/future/img3.jpeg'
import Img4 from '../img/future/img4.jpeg'
import Img5 from '../img/future/img5.jpeg'
import Img6 from '../img/future/img6.jpeg'
import Img7 from '../img/future/img7.png'
import Img8 from '../img/future/img8.png'
import Img9 from '../img/future/img9.png'
export default function Future() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <Layout>
                <H4>
                    Future 〜今を読み解き、みらいを考える〜
                </H4>
                <div className="grid gap-2 sm:grid-cols-1">
                    <div>
                        <div className="p-2">
                            <H5>
                                社会人向け
                            </H5>
                            <div className="rounded-xl">
                                <a href="/pdf/leaflet_elemental.pdf" download >
                                    <img src={Img1} className="mb-3 cursor" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <H6>
                    「今を読み解き、みらいを考える」
                </H6>
                <H6>
                    変化する社会の中で自ら考え、活躍できる人づくり
                </H6>
                <H4>
                    「FUTURE」特徴
                </H4>
                <div className="grid gap-2 sm:grid-cols-1">
                    <div>
                        <div className="p-2">
                            <div className="rounded-xl">
                                <a href="/pdf/leaflet_elemental.pdf" download >
                                    実社会、実生活と結びつく様々なテーマに対して、子どもたちが主体的に課題を設定し、
                                    情報の収集や整理・分析をしてまとめるといった能力を育成し、「変化する社会の中で、自ら考え、活躍できる人づくり」を目指します。<br /><br />
                                    SDGs、キャリア、マーケティングなど多岐にわたるテーマを収録しており、ワークシート式テキストとグループディスカッションを組み合わせた構成になっています。
                                    各テーマ1時間程度で完結できる内容で、新聞コラムや時事テーマなど、様々なジャンルの文章を課題設定のための情報として掲載しています。
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <H2>
                    こんな課題はありませんか？　解決方法をご提案いたします。
                </H2>
                <div className="grid gap-2 sm:grid-cols-1">
                    <div>
                        <div className="p-2">
                            <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                                <ul>
                                    <li className='p-1'>
                                        ・SDGsに関する取り組みを始めたいが、どのように進めればよいかわからない
                                    </li>
                                    <li className='p-1'>
                                        ・教育型CSRに関心があるが、教材作りやイベントの組み立てにコストが掛かる
                                    </li>
                                    <li className='p-1'>
                                        ・子どもやファミリー層へのアプローチを行いたいが、教育業界との接点がない
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <H2>
                    小学校高学年・中学受験希望者
                </H2>
                <div className="grid gap-2 sm:grid-cols-2">
                    <div>
                        <div className="p-2">
                            <img src={Img2} className="mb-3 cursor" alt="" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <ul>
                                <li className='p-1 text-blue-500 text-lg font-bold'>
                                    仲間とともに話し合いをすることで、視野を広げ、自分を知ります。
                                </li>
                                <li className='p-1 font-bold'>
                                    ・テーマ：10＋付録２
                                </li>
                                <li className='p-1 font-bold'>
                                    ・価格　：￥1,500（税別）
                                </li>
                                <li className='p-1'>
                                    1. クラスの係はいくつ必要か？
                                </li>
                                <li className='p-1'>
                                    2. 席替えのくじ引きって公平？
                                </li>
                                <li className='p-1'>
                                    3. 多数決？ジャンケン？
                                </li>
                                <li className='p-1'>
                                    4. 黒板の落書きは「自由」なの？
                                </li>
                                <li className='p-1'>
                                    5. 自由と自由がぶつかったらどうする？
                                </li>
                                <li className='p-1'>
                                    6. コンビニで１カ月３万円生活
                                </li>
                                <li className='p-1'>
                                    7. 大人気のピザを作ろう
                                </li>
                                <li className='p-1'>
                                    8. 動物園の宣伝ポスターを作ろう
                                </li>
                                <li className='p-1'>
                                    9. われらSDGs探検隊
                                </li>
                                <li className='p-1'>
                                    10. 未来に残したい仕事とは？
                                </li>
                                <li className='p-1'>
                                    11. 付録１：これからの社会で求められる力やスキルは？
                                </li>
                                <li className='p-1'>
                                    12. 付録２：超ヒマ社会をつくる
                                </li>
                                <li className='p-1 pt-2' onClick={()=>navigate(`/contact`)}>
                                    <img src={Banner1} className="mb-3 cursor" alt="" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <H2>
                    中学生・高校生 Vol.1
                </H2>
                <div className="grid gap-2 sm:grid-cols-2">
                    <div>
                        <div className="p-2">
                            <img src={Img3} className="mb-3 cursor" alt="" />
                        </div>
                    </div>
                    <div>
                        <ul>
                            <li className='p-1 text-blue-500 text-lg font-bold'>
                                正解のない問いの探究を通し、視野を広げ社会（未来）とつながるための自己決定力を養う。
                            </li>
                            <li className='p-1 font-bold'>
                                ・テーマ：20
                            </li>
                            <li className='p-1 font-bold'>
                                ・価格　：￥1,500（税別）
                            </li>
                            <li className='p-1'>
                                1. これからの社会で求められる力やスキルとは？
                            </li>
                            <li className='p-1'>
                                2. 超ヒマ社会をつくる
                            </li>
                            <li className='p-1'>
                                3. 未来に残したい仕事とは？
                            </li>
                            <li className='p-1'>
                                4. やりたい仕事の本質に迫ろう!
                            </li>
                            <li className='p-1'>
                                5. 「探究」を体感してみよう
                            </li>
                            <li className='p-1'>
                                6. あなたは文系？それとも理系？
                            </li>
                            <li className='p-1'>
                                7. 学部・学科への道
                            </li>
                            <li className='p-1'>
                                8. おにぎりをバラバラにしよう
                            </li>
                            <li className='p-1'>
                                9. コンビニで１カ月３万円生活
                            </li>
                            <li className='p-1'>
                                10. レジ前のチャンスを逃すな！
                            </li>
                            <li className='p-1'>
                                11. 大人気のピザを作ろう
                            </li>
                            <li className='p-1'>
                                12. 完売大作戦
                            </li>
                            <li className='p-1'>
                                13. きれいなキャンプ場にするために
                            </li>
                            <li className='p-1'>
                                14. 動物園の宣伝ポスターを作ろう
                            </li>
                            <li className='p-1'>
                                15. 政策を掲げて知事を目指そう
                            </li>
                            <li className='p-1'>
                                16. その情報、大丈夫？
                            </li>
                            <li className='p-1'>
                                17. もし著作権がなくなったら？
                            </li>
                            <li className='p-1'>
                                18. わかりやすいノートを作ろう
                            </li>
                            <li className='p-1'>
                                19. ファシリテーター・トレーニング①
                            </li>
                            <li className='p-1'>
                                20. ファシリテーター・トレーニング②
                            </li>
                            <li className='p-1 pt-2' onClick={()=>navigate(`/contact`)}>
                                <img src={Banner1} className="mb-3 cursor" alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
                <H2>
                    中学生・高校生 Vol.2
                </H2>
                <div className="grid gap-2 sm:grid-cols-2">
                    <div>
                        <div className="p-2">
                            <img src={Img4} className="mb-3 cursor" alt="" />
                        </div>
                    </div>
                    <div>
                        <ul>
                            <li className='p-1 text-blue-500 text-lg font-bold'>
                                正解のない問いの探究を通し、視野を広げ社会（未来）とつながるための自己決定力を養う。
                            </li>
                            <li className='p-1 font-bold'>
                                ・テーマ：20
                            </li>
                            <li className='p-1 font-bold'>
                                ・価格　：￥1,500（税別）
                            </li>
                            <li className='p-1'>
                                1. 哲学とは一体どのようなことを学ぶものだろうか
                            </li>
                            <li className='p-1'>
                                2. 「常識」って何だろう？
                            </li>
                            <li className='p-1'>
                                3. パスタを食べるならばフォークか箸か
                            </li>
                            <li className='p-1'>
                                4. 宇宙人ってどんなイメージ？
                            </li>
                            <li className='p-1'>
                                5. 新しい数字を発明しよう
                            </li>
                            <li className='p-1'>
                                6. 自分を「漢字１字」で表すと
                            </li>
                            <li className='p-1'>
                                7. 配慮すること自体が偏見になる？
                            </li>
                            <li className='p-1'>
                                8. 反省文の反省文が持っている意味
                            </li>
                            <li className='p-1'>
                                9. する「いじめ」・しない「いじめ」
                            </li>
                            <li className='p-1'>
                                10. 黒板の落書きは「自由」なの？
                            </li>
                            <li className='p-1'>
                                11. 自由と自由がぶつかったらどうする？
                            </li>
                            <li className='p-1'>
                                12. 席替えのくじ引きって公平？
                            </li>
                            <li className='p-1'>
                                13. 多数決？ジャンケン？
                            </li>
                            <li className='p-1'>
                                14. ベストな時間割をデザインしよう
                            </li>
                            <li className='p-1'>
                                15. クラスの係はいくつ必要か？
                            </li>
                            <li className='p-1'>
                                16. 学校に設置するならばどちらだろう
                            </li>
                            <li className='p-1'>
                                17. 新設するなら「〇〇レーン」
                            </li>
                            <li className='p-1'>
                                18. もし、電気が使えなくなったら
                            </li>
                            <li className='p-1'>
                                19. われらSDGs探検隊
                            </li>
                            <li className='p-1'>
                                20. 平和な世界を実現するために
                            </li>
                            <li className='p-1 pt-2' onClick={()=>navigate(`/contact`)}>
                                <img src={Banner1} className="mb-3 cursor" alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
                <H2>
                    Vol.3  STEAM
                </H2>
                <div className="grid gap-2 sm:grid-cols-2">
                    <div>
                        <div className="p-2">
                            <img src={Img5} className="mb-3 cursor" alt="" />
                        </div>
                    </div>
                    <div>
                        <ul>
                            <li className='p-1 text-blue-500 text-lg font-bold'>
                                ・Science ・Technology ・Engineering
                                ・Arts ・Mathematics
                            </li>
                            <li className='p-1 font-bold'>
                                ・テーマ：20
                            </li>
                            <li className='p-1 font-bold'>
                                ・価格　：￥1,500（税別）
                            </li>
                            <li className='p-1'>
                                1. STEAMって何？
                            </li>
                            <li className='p-1'>
                                2. わくわくする研究はどっちだ？
                            </li>
                            <li className='p-1'>
                                3. 科学探偵になろう
                            </li>
                            <li className='p-1'>
                                4. 金属オリンピックを開催しよう
                            </li>
                            <li className='p-1'>
                                5. 出かけたいけど服がない！
                            </li>
                            <li className='p-1'>
                                6. お腹の中の赤ちゃんのこと、どこまで知りたい？
                            </li>
                            <li className='p-1'>
                                7. 情報は三つの顔を持っている
                            </li>
                            <li className='p-1'>
                                8. 新しいペットボトルを作ろう
                            </li>
                            <li className='p-1'>
                                9. 飛ばない紙飛行機をしっかり作ろう
                            </li>
                            <li className='p-1'>
                                10. できるだけ高く、しかも確実に！
                            </li>
                            <li className='p-1'>
                                11. AI 先生と人間先生、どちらに教わりたい？
                            </li>
                            <li className='p-1'>
                                12. ロボットが人間と戦う世界
                            </li>
                            <li className='p-1'>
                                13. イスの足って何本がベスト？
                            </li>
                            <li className='p-1'>
                                14. 建築士になったつもりでプレゼン
                            </li>
                            <li className='p-1'>
                                15. ドローンタクシーを宣伝しよう
                            </li>
                            <li className='p-1'>
                                16. 新しい国の名前は？
                            </li>
                            <li className='p-1'>
                                17. 数字で仲間探し
                            </li>
                            <li className='p-1'>
                                18. 迷路で真剣勝負！
                            </li>
                            <li className='p-1'>
                                19. STEAMで考える大学入試問題
                            </li>
                            <li className='p-1'>
                                20. 学びの設計書
                            </li>
                            <li className='p-1 pt-2' onClick={()=>navigate(`/contact`)}>
                                <img src={Banner1} className="mb-3 cursor" alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
                <H4>
                    FUTURE 活用のメリット
                </H4>
                <div className="grid gap-2 sm:grid-cols-1 pb-4">
                    <div>
                        <H2>
                            ①SDGs への取り組みと教育市場開拓の両立
                        </H2>
                        <div className="p-2">
                            全国2,300教室の学習塾・学校への教材導入実績ノウハウを活用し、教育市場の開拓ができます。<br />
                            また「FUTURE」を活用することで、最小限のコスト・工数で SDGs に関する取り組みを実現します。
                        </div>
                    </div>
                    <div>
                        <H2>
                            ②ファミリー層へのPR効果・次世代へのブランディング
                        </H2>
                        <div className="p-2">
                            自社の製品・取り組みに対するファミリー層への認知度向上やイメージアップに繋がります。<br />
                            また、次世代の顧客である子どもたちへ直接企業の思いを届けることができます。
                        </div>
                    </div>
                    <div>
                        <H2>
                            ③子どもたちとの交流・社員の方々の意欲喚起
                        </H2>
                        <div className="p-2">
                            イベントにおいては、社員の皆さまも子どもたちとのグループワークに取り組んでいただくことが可能です。<br />
                            子どもたちの声を直接聞く機会になり、社員の方々の意欲喚起にも繋がります。
                        </div>
                    </div>
                </div>
                <H4>
                    FUTURE 学びのメソッド
                </H4>
                <div className="grid gap-2 sm:grid-cols-1 pb-4">
                    <div>
                        <div className="p-2">
                            <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                                <ul>
                                    <li className='p-1'>
                                        ・文章の読み取り／課題設定
                                    </li>
                                    <li className='p-1'>
                                        ・課題の整理
                                    </li>
                                    <li className='p-1'>
                                        ・課題の共有・議論
                                    </li>
                                    <li className='p-1'>
                                        ・思考のまとめ・作文
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <H4>
                    活用テーマ例
                </H4>
                <div className="grid gap-2 sm:grid-cols-1 pb-4">
                    <div>
                        <img src={Img6} className="mb-3 cursor" alt="" />
                    </div>
                    <div>
                        <div className="p-2">
                            <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                                <ul>
                                    <li className='p-1'>
                                        ☆総合型選抜（AO）・学校推薦型選抜（推薦）の対策として、 自ら考え、 表現する能力を養います
                                    </li>
                                    <li className='p-1'>
                                        ☆2022年度から導入される「探究」や、 総合、 道徳の学習教材として活用できます
                                    </li>
                                    <li className='p-1'>
                                        ☆学びや仕事の目的を発見することで、 意欲換気、 生きる力の育成につながります
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="grid gap-2 sm:grid-cols-1">
                    <div>
                        <img src={Img7} className="mb-3 cursor" alt="" />
                    </div>
                    <div>
                        <img src={Img8} className="mb-3 cursor" alt="" />
                    </div>
                    <div>
                        <img src={Img9} className="mb-3 cursor" alt="" />
                    </div>
                </div>
            </Layout>
        </>
    )
}