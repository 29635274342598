import reactStringReplace from "react-string-replace"
import Header from './elements/Header'
import Layout from './elements/Layout'
import { Slider2WithContent } from './elements/Slider'
import { Image2WithContent, Image1, Image2 } from './elements/Image'
import { H2, H3, H4, H5 } from './elements/Heading'
import { useNavigate } from "react-router-dom"

import Img1 from '../img/cheerstudy/img1.png'
import Img2 from '../img/cheerstudy/img2.png'
import Img3 from '../img/cheerstudy/img3.png'
import Img4 from '../img/sdgs-work/img4.jpeg'
import Img5 from '../img/sdgs-work/img5.jpeg'
import Img6 from '../img/sdgs-work/img6.jpeg'

import Swiper1 from '../img/miyapickles/swiper1.jpeg'
import Swiper2 from '../img/miyapickles/swiper2.jpeg'
import Swiper3 from '../img/miyapickles/swiper3.jpeg'
import Swiper4 from '../img/miyapickles/swiper4.jpeg'

import Banner1 from '../img/miyapickles/banner1.png'

export default function Miyapickles() {
    const navigate = useNavigate()
    const regExp = /(https?:\/\/\S+)/g
    return (
        <>
            <Header />

            <Layout>

                <H4>
                    チアスタディ
                </H4>
                <Image2 imgs={[
                    { img: Img1 },
                    { img: Img2 }
                ]} />
                {/* <div className="mb-3">
                    <a href="/pdf/fax_blank.pdf" download >
                        <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >保護者向けの申込書をダウンロード</button>
                    </a>
                </div> */}

                <H2>本書の特色</H2>
                <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                    <ul>
                        <li className='p-1'>■ 特徴１：チアスタディは、読解力 → 語彙力 → 思考力の３ステップ学習です。</li>
                        <li className='p-1'>■ 特徴２：読書を楽しむだけでは身につかない本質的な読解スキルの習得を目指します。</li>
                        <li className='p-1'>■ 特徴３：言語情報の読解トレーニングに加えて図形・表などの非言語情報の読解トレーニングにも取り組みます。</li>
                        <li className='p-1'>■ 特徴４：学ぶ目的と活用方法をしっかり伝えるオリジナル授業動画で理解度アップ！</li>
                        <li className='p-1'>■ 特徴５：『教科を貫く読解力』を育成するために全20ジャンルの内容を使った問題を出題します。</li>
                        <li className='p-1'>■ 特徴６：「思考力養成」のステップで取り組む問題は中学受験算数の基礎学力養成にも効果的です。</li>
                    </ul>
                </div>

                <H2>チアスタディの学習システムの仕組みと機能</H2>
                <div className="rounded bg-amber-50 border border-red-gray px-4 py-3">
                    <ul>
                        <li className='p-1'>■ チアスタディは５つのステージで構成されています。</li>
                        <li className='p-1'>■ 各章の学習後、課題として「宿題」と「確認テスト」に取り組んでいただきます。</li>
                        <li className='p-1'>■ 復習機能として、「振り返り学習」機能と「一覧復習」機能を実装しています。</li>
                        <li className='p-1'>■ 学習履歴管理機能で、学習時間の履歴や演習問題の正答率などを確認できます。</li>
                    </ul>
                </div>

                <div className="grid gap-2 sm:grid-cols-2">
                    <div>
                        <div className="p-2">
                            <div>
                                <div className="grid gap-2 lg:grid-cols-1">
                                    {[
                                        {
                                            img: Img4,
                                            url: `/contact`
                                        },
                                        {
                                            img: Img5,
                                            url: `/contact-kojin`
                                        }
                                    ].map((item, key) => (
                                        <div key={key}>
                                            <div>
                                                <div className="cursor" onClick={() => navigate(item.url)}>
                                                    <Image1 img={item.img} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="p-2">
                            <div>
                                <img src={Img6} className="mb-3" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        </>
    )
}