import { useNavigate } from "react-router-dom"

import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image3 } from './elements/Image'

import Img1 from '../img/hensyu/img1.jpeg'
import Img2 from '../img/hensyu/img2.jpeg'
import Img3 from '../img/hensyu/img3.jpeg'
import { Navigate } from 'react-router-dom'

export default function Hensyu() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <Layout>
                <H4>
                    【編集部】編集・出版実績、商品紹介
                </H4>
                <H2>
                    受験スケジュール帳
                </H2>
                <div className="flex flex-col sm:flex-row">
                    <div className="sm:w-1/4 p-2">
                        <img src={Img1} className="mb-3 cursor" alt="" />
                    </div>
                    <div className="sm:w-3/4 p-2">
                        「初めてのお子様の受験でどうしたらいいか分らないママ必見です！」をテーマに生まれたお母さん・
                        お父さん・おばあちゃま・おじいちゃまへ向けた受験専用のスケジュール帳です。<br /><br />

                        2021年度版から弊社編集部で編集制作を担当しています。
                        合格だるまのキャラクターの制作デザイン、１ページごとに丁寧にリニューアルに向けた打ち合わせを重ねて編集
                        制作いたしました。<br /><br />

                        『受験を経験した，多くのママの声から生まれた手帳だから、受験生のママのお悩みを解消できる！
                        アナログママの【受験スケジュール帳】です。』<br /><br />
                    </div>
                </div>
                <H2>
                    SAKURASAKU NOTE（サクラサク ノート）
                </H2>
                <div className="flex flex-col sm:flex-row">
                    <div className="sm:w-1/4 p-2">
                        <img src={Img2} className="mb-3 cursor" alt="" />
                    </div>
                    <div className="sm:w-3/4 p-2">

                        <span className="font-bold">
                            学習塾・学校のみなさまへ
                        </span>

                        <br /><br />

                        保護者様へのささやかな贈り物にいかがですか？？
                        年度や配布時期を選ばない“受験スケジュール手帳”は、保護者説明会や入学説明会などで保護者へ配布できる
                        A5サイズ４８ページの冊子仕様の手帳です。<br /><br />

                        手帳にありがちなカレンダー機能を外し、あくまでも受験に必要な情報を書き留めていけるように編集
                        いたしました。ページの組み換えが自由にできます。貴校の情報を編集できるページ・表紙や各ページにロゴを入れることが可能です。受験を意識した時期から合格までの記録を残すことができる、保護者にとっては「第２の母子手帳」として喜ばれています。
                    </div>
                </div>
                <div className="mb-3" onClick={()=>navigate(`/sakurasakunote`)}>
                    <button className="w-full bg-pink-100 text-pink-500 text-bold border border-pink-500 p-3 rounded-lg">SAKURASAKU NOTE 詳細</button>
                </div>
                <H2>
                    SDGs マイスター検定 問題集
                </H2>
                <div className="flex flex-col sm:flex-row">
                    <div className="sm:w-1/4 p-2">
                        <img src={Img3} className="mb-3 cursor" alt="" />
                    </div>
                    <div className="sm:w-3/4 p-2">

                        高校生～社会人を対象に、教育の場や企業の中でSDGsの理解と人材育成、実践力の向上を目的とした
                        書籍です。日本で活躍する企業の取り組み事例を多く取り入れておりますので、SDGsに関する様々な研修に
                        参加する際の参考書籍として活用いただける内容に編集いたしました。

                        <br /><br />

                        <span className="font-bold">
                            編集部より
                        </span>

                        <br /><br />

                        今回、学校法人のクライアント様より夏に制作依頼を受け問題の執筆編集をいたしました。
                        取り組み事例については，各企業へ協力依頼をしましたところ、多くの企業様から応援メッセージをいただきました。
                        今回は編集部でも前例のないSDGsの「問題集」の執筆編集を通して多くのことを学びました。
                        ご協力いただきました企業様をはじめ，多くのみなさまの協力を得て本書を仕上げることができました。
                        心より感謝いたします。ありがとうございました。
                    </div>
                </div>
            </Layout>

        </>
    )
}