import reactStringReplace from "react-string-replace"
import Header from './elements/Header'
import Layout from './elements/Layout'
import { H2, H4 } from './elements/Heading'
import { Image1, Image2, Image2_3, Image3 } from './elements/Image'

import Img1 from '../img/recruit/img1.png'

export default function Recruit() {
    const regExp = /(https?:\/\/\S+)/g
    return (
        <>
            <Header />
            <Layout>
                <H4>Terra-Cco Club（テラッコ クラブ）教室運営スタッフ募集！</H4>
                <div className="flex flex-col sm:flex-row mt-5 mb-5">
                    <div className="sm:w-1/3">
                        <img src={Img1} className=" w-full object-cover object-center" alt="" />

                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                        <ul>
                            <li className="m-3 text-md">
                                株式会社Terra-Cco（テラッコ）の文化事業部が運営する
                                「Terra-Cco Club（テラッコ クラブ）」が９月上旬、
                                品川区大井町駅近くにオープン！
                            </li>
                            <li className="m-3 text-md">
                                コンセプトは、学童からシニアまで幅広く、未来への
                                土台作りや学びなおしができる楽しい「学びの場」です！
                            </li>
                            <li className="m-3 text-md">
                                一緒に楽しい学びの場を運営するスタッフをお待ちしております。

                                Engageに登録しましたので是非ご覧くださいね！
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mb-3">
                    {reactStringReplace('https://en-gage.net/terra-cco_jobs/', regExp, (match, i) => (
                        <a href={match} target="_blank" rel="noopener noreferrer">
                            <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" >スタッフ募集 お問い合わせ</button>
                        </a>
                    ))}
                </div>
            </Layout>
        </>
    )
}